@import "refinement_flyout";

.pt_product-search-result,
.pt_categorylanding_lifestyle,
.wishlist-single,
.sti-flyout {
    .pagewrap {
        max-width: none;
        padding: 0;

        @include bp(max-width, $bp-large) {
            padding: 0;
        }

        &.has-romance-content {
            padding: 0;
        }

        .checkbox-refinements {
            color: $clr_dark_mid;
            position: absolute;
            flex-wrap: wrap;
            border: 1px solid $very-light-gray;
            padding: 20px 20px 4px;
            transform: translateX(-12px);
            z-index: 1;

            .show-less {
                display: block;
            }
        }

        .refinement-color {
            .color-swatch {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid transparent;
                background: var(--bgColorRefinement);
                margin: 0 9px -3px 0;
            }

            .selected {
                .color-swatch {
                    border-color: $clr_brand_black;
                }
            }
        }

        .refinements-toggle {
            display: none;
        }
    }

    .primary-content {
        border: none;
        width: 100%;
        padding-left: 0;
        float: none;

        .sort-by.refinement .input-box.ba-select-container.option-selected {
            .ba-select.ba-select-box.ba-over.open {
                .ba-options {
                    @include bp(max-width, 375px) {
                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }
            .ba-options {
                @include bp(max-width, 375px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        h1.content-header {
            padding-left: 15px;
        }
    }

    .header-hero {
        color: $clr_brand_black;
        margin: 0 auto;
        padding-bottom: 3em;
        padding-left: 32px;
        padding-right: 32px;

        .breadcrumb {
            margin: 0 -17px;
            padding: 10px 0 15px;

            @include bp(min-width, $bp-medium) {
                margin: 0;
                padding: 20px 0 10px;
            }
        }

        @include bp(max-width, $bp-large) {
            padding-bottom: 2em;
        }

        @include bp(min-width, $bp-medium) {
            padding-right: 56px;
            padding-left: 56px;
        }

        @include bp(min-width, $bp-custom-large) {
            padding-right: 100px;
            padding-left: 100px;
        }
    }

    .product-tile {
        .product-image {
            height: var(--product-tile-image-height);

            @include bp(min-width, $bp-large) {
                flex-shrink: 0;
            }

            &.gradient {
                &::after {
                    background: linear-gradient(rgba(240, 240, 240, 0), rgba(240, 240, 240, 0.4635) 68.75%, rgba(240, 240, 240, 0.5077) 99.97%, rgba(240, 240, 240, 1) 99.98%, rgba(240, 240, 240, 0.5077));
                    bottom: 0;
                    content: "";
                    height: 4%;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        }

        img {
            height: 100%;
            width: 100%;
        }

        .product-promo {
            font: normal normal 10px/14px $f-avenir;
            color: $dark-sage;
            text-transform: none;
            margin: 0 0 5px;

            @include bp(min-width, $bp-medium) {
                font: normal normal 12px/16px $f-avenir;
            }

            @include bp(min-width, $bp-large) {
                font: normal normal 14px/19px $f-avenir;
            }
        }

        .product-prices {
            margin-bottom: 5px;

            .product-pricing {
                flex-wrap: wrap;
            }

            .is-active {
                color: $promo-price-color-v2;
                &:not(.clearance) {
                    padding-right: 5px;
                }
            }
        }

        .price-type {
            span {
                display: none;

                @include bp(min-width, $bp-medium) {
                    display: inline-block;
                    margin-right: 2px;
                }
            }
        }

        .tool-tip {
            position: relative;
            font-size: 0;
            line-height: normal;
            margin-left: 5px;
            align-self: center;
            z-index: 11;
            display: block;

            .icon {
                display: inline-block;
                width: 12px;
                height: 12px;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url("../images/plp_details_icon.svg") left top no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .tool-tip-content {
                display: none;
            }

            &:hover,
            &:focus,
            &.tooltip-hover {
                .icon {
                    &::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        background: $brand-v2-pink-cream;
                        border: 1px solid $brand-v2-lt-gray;
                        border-width: 1px 0 0 1px;
                        transform: rotate(45deg) translate(100%, 100%);
                        z-index: 10;
                    }
                }

                .tool-tip-content {
                    position: absolute;
                    display: block;
                    background: $brand-v2-pink-cream;
                    padding: 20px;
                    width: 320px;
                    z-index: 30;
                    color: $brand-v2-off-black;
                    top: 26px;
                    border: 1px solid $brand-v2-lt-gray;
                    border-radius: 10px;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                    @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);
                    white-space: normal;

                    p,
                    strong {
                        @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);
                    }

                    &::before {
                        content: "";
                        height: 26px;
                        width: 320px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translateY(-100%);
                    }
                }
            }
        }

        .owl-carousel-container {
            height: 100%;
        }
    }

    .product-tile,
    .grid-slot {
        transition: all 0s ease 0s;
        display: flex;
        flex-direction: column;
        height: 100% !important;
        text-align: left;
        padding: 0;
        margin: 0;

        &::after {
            display: none;
        }

        .product-image {
            padding: 0;
            margin-bottom: 10px;

            @include bp(min-width, $bp-medium) {
                margin-bottom: 20px;
            }
        }

        .product-info {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .product-name {
            display: flex;
            flex-grow: initial;
            justify-content: space-between;
            height: initial;
            margin-bottom: 10px;
            max-height: initial;
            min-height: initial;
        }

        .price-type {
            padding-left: 5px;

            span {
                margin: 0;
            }
        }

        .owl-controls {
            position: absolute;
            bottom: 5%;
            left: 50%;
            margin: 0;
            top: initial;
            transform: translateX(-50%);
            z-index: initial;
        }

        .owl-nav {
            display: none;
        }

        .owl-dots {
            font-size: 0;
            line-height: normal;
        }

        .owl-theme {
            .owl-dot {
                span {
                    width: 8px;
                    height: 8px;
                    background: $white;
                    border: 1px solid $tile-carousel-dot-border-color;
                    border-radius: 50%;
                }

                &.active {
                    span {
                        background: $tile-carousel-dot-active-background;
                    }
                }
            }
        }
    }

    .grid-tile {
        background: $white;
        padding: 0 10px;
        margin-bottom: 20px;

        @include bp(min-width, $bp-medium) {
            width: 50%;
            margin-bottom: 30px;
        }

        @include bp(min-width, $bp-large) {
            width: calc(100% / 3);
            margin-bottom: 52px;
        }

        &.grid-slot-tile {
            &__full-width {
                width: 100%;
            }

            &__2-columns {
                width: 100%;

                @include bp(min-width, $bp-large) {
                    width: calc(200% / 3);
                }
            }

            &__1-column {
                width: 50%;

                @include bp(min-width, $bp-large) {
                    width: calc(100% / 3);
                }
            }

            img {
                max-width: 100%;
            }
        }
    }

    .grid-slot {
        padding: 5px;
        overflow: hidden;

        @include bp(min-width, $bp-medium) {
            padding: 0;
        }
    }

    .search-result-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding: 30px 0 0;
        list-style: none;

        @include bp(min-width, $bp-medium) {
            padding-top: 40px;
        }

        @include bp(min-width, $bp-large) {
            padding-top: 50px;
        }

        &::before {
            display: none;
        }

        li {
            list-style: none;
        }
    }

    .search-result-content {
        padding: 0 32px;
        margin: 0;
        max-width: none;

        @include bp(min-width, $bp-medium) {
            padding: 0 56px;
        }

        @include bp(min-width, $bp-custom-large) {
            padding: 0 100px;
        }
    }

    .category-top-description {
        margin: 0 auto;
        text-align: center;

        p {
            margin-bottom: 0;
        }

        @include bp(max-width, $bp-medium) {
            width: 100%;
        }
    }

    .color-swatch {
        display: none;
    }

    .results-hits {
        margin-right: 20px;
        position: static;
        top: auto;
        right: auto;

        @include bp(min-width, $bp-medium) {
            margin-right: 0;
        }

        @include bp(min-width, $bp-large) {
            letter-spacing: normal;
        }
    }

    .desktop-view {
        display: none;

        @media screen and (min-width: 551px) {
            display: block;
        }
    }

    .tablet-view {
        display: block;

        @media screen and (min-width: 551px) {
            display: none;
        }
    }

    .mobile-view {
        display: block;

        @media screen and (min-width: 551px) {
            display: none;
        }
    }

    .search-filters {
        display: none;
        width: 100%;

        @include bp(min-width, $bp-large) {
            display: block;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding: 0;
            margin: 0 -5px;
        }

        .breadcrumb-refinement {
            display: flex;
            flex-wrap: wrap;
            text-indent: initial;
            line-height: initial;
        }

        .breadcrumb-refinement-value {
            padding: 0 4px;
            margin: 16px 0 0;
        }

        .breadcrumb-relax {
            background: $clr_white;
            border: 1px solid $breadcrumb-refinement-value-border-color;
            border-radius: 100px;
            color: $clr_brand_black;
            display: block;
            font: normal normal 12px/16px $f-avenir;
            margin: 0;
            padding: 5px 37px 5px 16px;
            position: relative;

            &::before {
                display: none;
            }

            &__x {
                position: absolute;
                top: 50%;
                right: 10px;
                width: 12px;
                height: 12px;
                transform: translateY(-50%) rotate(45deg);
                cursor: pointer;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    background: $clr_brand_black;
                }

                &::before {
                    height: 1px;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::after {
                    width: 1px;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &.clear-all {
                border: 0 none;
                background: transparent;
                text-decoration: underline;
                border-radius: initial;
                padding: 4px 0;
                margin-left: 10px;
            }
        }

        .refinement-header {
            display: none;
        }
    }

    .refinements {
        display: flex;
        align-items: center;
        background: $search-refinements-background;
        border-top: 1px solid $search-refinements-border-color;
        border-bottom: 1px solid $search-refinements-border-color;
        position: relative;
        padding: 0 30px;
        width: 100%;
        max-width: $bp-custom-xxlarge;
        float: none;
        height: 47px;

        @include bp(min-width, $bp-medium) {
            padding: 0 60px;
        }

        @include bp(min-width, $bp-large) {
            height: auto;
            padding-top: 16px;
            padding-bottom: 14px;
        }

        @include bp(min-width, $bp-custom-large) {
            padding-right: 100px;
            padding-left: 100px;

        }

        &::before,
        &::after {
            content: "";
            display: none;
            position: absolute;
            top: -1px;
            bottom: -1px;
            width: 100vw;
            background: inherit;
            border: inherit;

            @include bp(min-width, $bp-large) {
                display: block;
            }
        }

        &::before {
            right: 100%;
        }

        &::after {
            left: 100%;
        }
    }

    .refinement {
        cursor: initial;
        width: auto;
        overflow: visible;
        color: $black;

        &.hide-desktop-refinement {
            @include bp(min-width, $bp-large) {
                display: none;
            }
        }

        &.opened {
            .refinement-header {
                &::after {
                    top: 40%;
                    transform: rotate(315deg);
                }
            }
        }

        .checkbox-refinements {
            > li {
                line-height: initial;
                margin-bottom: 16px;
            }
        }
    }

    .refinement-link {
        font: normal normal 14px/16px $f-avenir;
        letter-spacing: 0.0025em;
        color: inherit;
    }

    .refinements-toggle {
        font: normal normal 14px/19px $f-avenir;
        color: inherit;
        padding: 0;
        margin-bottom: 16px;
    }

    .clear-refinement {
        display: none;
    }

    .view-more-button,
    .view-more-tiles-loader {
        margin-bottom: 90px;
    }

    .view-more-button {
        border: 1px solid $brand-v2-off-black;
        color: $brand-v2-off-black;
        font: normal normal 14px/19px $f-avenir;
        max-width: initial;
        padding: 11px 10px;
        text-transform: uppercase;
        width: auto;
    }

    .seo-region,
    .plp-bottom-content,
    .plp-asset {
        margin: 0 auto;
        max-width: $bp-custom-xxlarge;
        padding: 0 20px;

        @include bp(min-width, $bp-medium) {
            padding: 0 60px;
        }

        @include bp(min-width, $bp-custom-large) {
            padding-right: 100px;
            padding-left: 100px;
        }
    }

    .plp-bottom-content {
        margin-top: 50px;
    }

    .starting-price-text {
        display: block;
        margin-bottom: 5px;
    }

    .product-swatches {
        font: normal italic 10px/14px $f-avenir;
        color: $dark-sage;
        height: initial;
        min-height: 0;
        padding-top: 0;

        @include bp(min-width, $bp-medium) {
            font: normal italic 12px/16px $f-avenir;
        }
    }

    .swatch-list {
        align-items: center;
        color: inherit;
        display: flex;
        font: inherit;
        list-style: none;
        margin: 0 0 10px;
        padding: 0;

        li {
            display: none;
            margin-right: 5px;

            @include bp(min-width, $bp-medium) {
                display: block;
                margin-right: 10px;
            }
        }
    }

    .swatch {
        background: var(--swatch-background) center / contain;
        border-radius: 50%;
        height: 16px;
        width: 16px;

        @include bp(min-width, $bp-medium) {
            height: 20px;
            width: 20px;
        }

        &.selected {
            border-color: $brand-v2-off-black;
            box-shadow: inset 0 0 0 2px $white;

            .swatch-image {
                border: 0 none;
            }
        }
    }

    .additional-swatch-count {
        color: inherit;
        display: none;
        font: inherit; // fix iOS issue
        font-family: $f-avenir; // fix iOS issue
        font-size: 10px; // fix iOS issue
        font-style: italic; // fix iOS issue
        font-weight: normal; // fix iOS issue
        line-height: 14px; // fix iOS issue
        top: initial;

        @include bp(min-width, $bp-medium) {
            display: block;
            font: inherit;
        }

        &.custom-badge {
            display: block;
        }
    }
}

.pt_product-search-result {
    .product-tile {
        .tool-tip {
            display: none;
        }
    }
}

.category-main-banner {
    img {
        max-width: 100%;
    }
}

.search-result-bookmarks {
    margin: 15px;
    .first {
        border-right: 1px solid $nobel;
        padding-right: 5px;
    }
}

.search-result-options {
    @extend %clearfix;
    display: none;
    width: auto;
    margin: 0 0 0 auto;
    padding: 0;
    overflow: visible;

    @include bp(min-width, $bp-large) {
        display: flex;
        align-items: center;
    }

    @include bp(max-width, $bp-large) {
        padding: 0;
    }

    &.top {
        @include bp(max-width, $bp-medium) {
            width: calc(100% - 30px);
            margin: 0 auto;
            white-space: nowrap;

            > div {
                white-space: normal;
            }

            .pagination {
                white-space: nowrap;
            }
        }
        .pagination {
            @include bp(max-width, 1290px) {
                margin-right: 1.98vw;
            }
        }
        @include bp(max-width, 767px) {
            margin-left: 4.98vw;
            margin-right: 7.98vw;
            width: calc(100% - 9.96vw);
        }
    }

    &.bottom {
        margin-top: 0;
        position: relative;

        @include bp(max-width, $bp-medium) {
            .sort-by {
                display: none;
            }

            .pagination {
                padding: 12px 15px;
            }
        }
    }
}

.mobile-collapse-container,
.sort-by {
    .radio-label,
    .checkbox-label {
        display: flex;
        font: inherit;
        letter-spacing: inherit;
        padding: 2px 0 0 31px;

        &::before {
            top: 0;
        }
    }

    .checkbox-label {
        &.checked {
            &::after {
                top: 6px;
            }
        }
    }

    .radio-label {
        &.checked {
            &::after {
                top: 3px;
            }
        }
    }
}

.mobile-collapse-container {
    display: none;
    width: auto;
    overflow: initial;

    @include bp(min-width, $bp-large) {
        display: block;
        padding-left: 12px;
    }

    > .refinement-header {
        display: none;
    }

    .collapse-element {
        display: none;
    }
}

.collapse-elements {
    display: flex;
}

.collapse-item {
    .collapse-element {
        display: none;
        grid-auto-flow: column;
        grid-template-columns: auto;
        grid-template-rows: repeat(6, auto);
        grid-column-gap: 24px;
        background: $white;

        &.higher {
            grid-template-rows: repeat(12, auto);
            overflow-x: auto;
        }
    }

    .not-active-onload {
        .collapse-element {
            display: none;
        }
    }

    &.opened {
        .collapse-element {
            display: grid;
        }
    }

    &.sort-by {
        .collapse-element {
            display: none;
            background: $white;
            z-index: 1;
        }

        &.opened {
            .collapse-element {
                display: flex;
            }
        }
    }
}

.results-hits {
    position: absolute;
    right: 10px;
    top: 30px;

    @include bp(min-width, $bp-medium) {
        display: block;
    }
}

.pagination {
    @extend %clearfix;
    float: right;
    clear: right;
    margin: 0;
    padding-top: 32px;

    .results-hits {
        position: absolute;
        right: 10px;
        top: 30px;
        + ul {
            margin-left: 60px;

            @include bp(max-width, $bp-large) {
                margin-left: 32px;
            }
        }
    }
    ul {
        float: right;
        margin: 0;
        padding: 0;
    }
    li {
        display: block;
        float: left;
        list-style: none;
        margin-right: 10px;
        line-height: 22px;

        &:last-child {
            margin-right: 0;
        }
    }
    .current-page {
        font-family: $f-neue;
        color: $brand-primary-dark;
        font-weight: 600;
        padding: 0 4px;
    }
    a {
        @include link($brand-grey);
        text-decoration: none;
        padding: 2px 4px;
    }

    .icon {
        @extend %icon-font;
        font-size: 12px;
    }

    .icon-angle-left::before {
        content: $icon-pagination-prev;
    }

    .icon-angle-right::before {
        content: $icon-pagination-next;
    }

    .icon-angle-double-left::before {
        content: $icon-pagination-first;
    }

    .icon-angle-double-right::after {
        content: $icon-pagination-last;
    }

    @include bp(max-width, $bp-medium) {
        padding-top: 0;
        float: none;
        clear: both;

        ul {
            float: left;
        }
    }

    @include bp(max-width, $bp-medium) {
        display: flex;
        padding: 0 0 4px;

        ul {
            text-align: center;
            width: 100%;
            flex: 0;
            display: flex;
            margin-left: 0;
        }

        li {
            display: inline-block;
            float: none;
        }

        > .results-hits {
            flex: 1;
            flex-basis: 70px;
        }
    }
}

.search-result-items {
    margin: 0;
    padding: 10px 0 0;
    @include clearfix;
    position: relative;

    &::before {
        @include position(absolute, null 0 -1px 0);
        content: "";
        background-color: $white;
        height: 1px;
        display: block;
        z-index: 3;
    }

    li {
        list-style: none outside none;
    }

    .grid-tile {
        background: none repeat scroll 0 0 $white;
        list-style: none outside none;
        width: 50%;
        padding: 0 7px;

        @include bp(min-width, $bp-large) {
            width: calc(100% / 3);
            padding: 0 10px;
        }
    }

    .invisible {
        display: none;
    }
}

.search-result-content {
    clear: both;
    @include bp(max-width, 1290px) {
        margin-right: 1.98vw;
    }
    @include bp(max-width, 767px) {
        margin-top: 21px;
        max-width: calc(100% - 9.96vw);
        margin-left: 4.98vw;
    }
    @include bp(max-width, 375px) {
        max-width: calc(100% - 8vw);
        margin-left: 4vw;
    }
}

#results-content {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    padding-top: 30px;
}

.folder-content-list {
    margin: 0;
    overflow: hidden;
    padding: 0 20px;
    li {
        float: left;
        list-style: none outside none;
        margin: 0;
        padding: 2% 2% 2% 0;
        width: 48%;
    }
    .content-title {
        display: block;
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    display: block;
    float: left;
    margin-right: 0.5em;
    i {
        cursor: pointer;
        margin-right: 0.3em;
    }
    [data-option="column"] {
        color: $citrus;
    }
    &.wide {
        [data-option="wide"] {
            color: $citrus;
        }
        [data-option="column"] {
            color: $dim-gray;
        }
    }
}

.wide-tiles {
    .search-result-items .grid-tile {
        width: 91%;
    }
    .product-tile {
        .product-swatches {
            bottom: auto;
            left: auto;
            position: relative;
            right: auto;
            margin-top: 5px;
            li {
                float: left;
            }
        }
    }
}

.jump-top {
    opacity: 0;
    position: fixed;
    right: 0;
    bottom: 60px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    background: #313131;
    flex-direction: column;
    font-size: $f-size-xs;
    line-height: $f-size-xxl;
    color: $white;
    z-index: 99;

    &.shown {
        opacity: 1;
        transition: all 0.3s ease;
        &:hover {
            cursor: pointer;
        }
        & span {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.view-more-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .view-more-status-text {
        display: none;
    }

    .view-more-status-bar {
        border-color: #54584e;
        border-style: solid;
        border-width: 1px;
        display: none;
        height: 10px;
        justify-content: center;
        margin-bottom: 10px;
        width: 33%;

        @include bp(max-width, $bp-small) {
            width: 100%;
        }

        .view-more-status-fill {
            height: 100%;
            width: 100%;

            &.filled {
                background-color: #54584e;
            }
        }
    }

    .view-more-button {
        background-color: transparent;
        border: 1px $black solid;
        color: $black;
        font-family: $f-primary;
        font-size: 12px;
        line-height: 18px;
        text-transform: none;
        width: 100%;

        &:disabled,
        &.with-infinite-scroll,
        &.sent {
            display: none;
        }

        @include bp(min-width, $bp-medium) {
            max-width: 136px;
        }
    }
}

.view-more-tiles-loader {
    display: none;
    align-items: center;
    justify-content: center;

    &.show {
        display: flex;
    }
}

.plp-bottom-content {
    margin: 50px 0 20px;
    padding: 0 20px;
}

.vn-seo-copy-wrapper {
    @include outer-container;
    @include pad(0 20px 15px 20px);

    @include bp(max-width, $bp-large) {
        @include pad(0 39px 15px 39px);
    }

    @include bp(max-width, $bp-xlarge) {
        @include pad(0 51px 15px 51px);
    }

    @include bp(min-width, $bp-xlarge) {
        @include pad(0 $container-padding 15px $container-padding);
    }
}

.related-categories {
    .section-header {
        background: transparent;
        margin: 0 0 30px;
        padding: 0;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        padding: 0;

        li {
            display: inline-block;
            margin: 10px 0 0;
            padding: 0 15px;

            a {
                background: transparent;
                padding: 0;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }
}

.fas {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        @include bp(min-width, $bp-large) {
            display: none;
        }
    }

    &__left,
    &__right,
    &__show-fas {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__left {
        flex: 0 0 50%;
        border-right: 1px solid $clr_dark_mid;
    }

    &__show-fas {
        cursor: pointer;

        .icon {
            margin-right: 10px;
        }
    }
}

.tct {
    &__wrapper,
    &__one-column,
    &__two-columns {
        display: flex;

        @include bp(min-width, $bp-medium) {
            display: none;
        }
    }

    &__one-column,
    &__two-columns {
        flex-wrap: wrap;
        cursor: pointer;
        height: 24px;
    }

    &__one-column {
        flex-direction: column;
        justify-content: space-between;
        width: 25px;
        margin-right: 10px;
    }

    &__two-columns {
        width: 27px;
        justify-content: space-between;
    }
}

.grid-one-column {
    .grid-tile {
        @include bp(max-width, $bp-medium) {
            width: 100%;
        }

        &.grid-slot-tile {
            &__1-column {
                @include bp(max-width, $bp-medium) {
                    width: 100%;
                }
            }
        }
    }

    .product-tile {
        .starting-price-text {
            @include bp(max-width, $bp-medium) {
                font: normal normal 12px/16px $f-avenir;
            }
        }

        .product-swatches {
            @include bp(max-width, $bp-medium) {
                font: normal italic 12px/16px $f-avenir;
            }
        }

        .product-promo {
            @include bp(max-width, $bp-medium) {
                font: normal normal 12px/16px $f-avenir;
            }
        }
    }

    .tct {
        &__one-column {
            rect {
                fill: $search-refinements-divide-border-color;
            }
        }
    }
}

.grid-two-columns {
    .search-result-content {
        @include bp(max-width, $bp-medium) {
            padding: 0 20px;
        }
    }

    .grid-tile {
        @include bp(max-width, $bp-medium) {
            width: 50%;
        }
    }

    .product-tile {
        .product-custom-badge {
            @include bp(max-width, $bp-medium) {
                top: 12px;
                font: normal normal 10px/14px $f-avenir;
            }
        }

        .owl-controls {
            @include bp(max-width, $bp-medium) {
                bottom: 0;
            }
        }

        .wishlist {
            @include bp(max-width, $bp-medium) {
                height: 17px;
                margin-right: 0;
                min-width: 12px;
                width: 12px;
            }
        }
    }

    .cc-price-tool-tip {
        @include bp(max-width, $bp-medium) {
            display: none;
        }
    }

    .tct {
        &__two-columns {
            rect {
                fill: $search-refinements-divide-border-color;
            }
        }
    }
}

.iga {
    &__wrapper {
        background: $alabaster;
        color: $black;
        height: 100%;
        position: relative;
        width: 100%;

        &.large {
            .iga {
                &__sub-title,
                &__title {
                    letter-spacing: 2.76px;
                    margin-bottom: 11px;
                }

                &__sub-title {
                    font: 900 normal 15px/24px $f-avenir;
                }

                &__title {
                    font: normal normal 40px/48px $f-classical-normal;
                }

                &__copy {
                    font: normal normal 18px/24px $f-classical-normal;
                    letter-spacing: 1.02px;
                    margin-bottom: 22px;
                }

                &__link {
                    font: 900 normal 15px/24px $f-avenir;
                    letter-spacing: 2.76px;
                }
            }
        }
    }

    &__inner {
        left: 50%;
        max-height: calc(100% - 24px);
        max-width: calc(100% - 24px);
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include bp(min-width, $bp-medium) {
            width: auto;
        }
    }

    &__sub-title,
    &__title,
    &__copy {
        margin-top: 0;
    }

    &__sub-title,
    &__title {
        letter-spacing: 1.5px;
        margin-bottom: 6px;
    }

    &__sub-title {
        font: 900 normal 8px/13px $f-avenir;
    }

    &__title {
        font: normal normal 22px/26px $f-classical-normal;
    }

    &__copy {
        font: normal normal 10px/13px $f-classical-normal;
        letter-spacing: 0.56px;
        margin-bottom: 13px;
    }

    &__link {
        color: inherit;
        font: 900 normal 8px/13px $f-avenir;
        letter-spacing: 1.5px;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}
