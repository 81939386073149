.pt_product-compare {
    .full-width {
        padding: 0;
        .print-page {
            float: right;
        }
        .back {
            padding: 1em 0;
        }
    }

    .primary-content {
        @extend %constrain-wrapper;
        padding: 50px 16px;

        @include bp(min-width, $bp-large) {
            padding: 50px 64px;
        }
    }

    .button-back {
        z-index: 100;
    }
}
.compare-bar {
    @extend %clearfix;
    margin-bottom: 30px;

    @include bp(max-width, $bp-xlarge) {
        width: 100%;
        max-width: 520px;
    }
}
.compare-items {
    clear: both;
    padding: 12px 0 0 10px;
    display: none;

    @include bp(max-width, $bp-medium) {
        display: none !important;
    }
}
.compare-heading {
    font: normal normal 14px/26px $f-neue-bold;
    color: $brand-primary-dark;
    letter-spacing: 1px;
    margin-bottom: 13px;
}
.compare-items-panel {
    @extend %clearfix;
    float: left;

    @include bp(max-width, $bp-xlarge) {
        width: 100%;
        float: none;
    }
}
.compare-remove {
    @extend %icon-font;
    font-size: 12px;

    &::before {
        content: $icon-close-bulky-x;
    }
}

.compare-item {
    background-color: $c-email-cell;
    float: left;
    height: 70px;
    margin: 0 20px 20px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 70px;
    z-index: 0;

    .compare-item-remove {
        color: $brand-grey;
        cursor: pointer;
        display: none;
        padding: 0 .16em;
        position: absolute;
        right: -2px;
        top: -3px;
        z-index: 1;
        line-height: 1;
        text-decoration: none;
    }
    &.active {
        .compare-item-remove {
            display: block;
        }
    }
    .compare-item-number {
        font: normal normal 37px/75px $f-neue;
        color: $brand-grey;
        letter-spacing: 0;
    }
    @include bp(max-width, 968px) {
        width: calc(33.333% - 13.333px);
        padding-bottom: calc(33.333% - 13.333px);

        &:nth-of-type(3n + 3) {
            margin-right: 0;
        }

        .compare-item-number {
            position: absolute;
            width: 100%;
            top: 50%;
            // magic number alert
            // if the font-size of the number changes, this will need to be adjusted
            margin-top: -37.5px;
        }
    }
    @include bp(max-width, $bp-xlarge) {
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.compare-item-image {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.compare-buttons {
    width: calc(100% - 540px);
    float: right;

    @include bp(min-width, $bp-medium) {
        width: 100%;
        float: none;
    }
}

.compare-clear-all {
    text-transform: none;
    padding: 0;
    height: 70px;
    letter-spacing: 0;
    font-family: $f-neue;

    &::after {
        display: none;
    }
}

#compare-items-button {
    @include position(relative, 16px -16px null null);
    float: right;

    &[disabled="disabled"] {
        background-color: transparent;
    }
}

.category-switch {
    @extend .clearfix;
    margin-bottom: 2em;
    label {
        float: left;
        margin: 0;
        width: 45%;
        @media (min-width: 410px) {
            width: 11em;
        }
    }
    select {
        float: left;
        margin: 0;
        width: 55%;
        @media (min-width: 410px) {
            margin-left: 1em;
            width: 15em;
        }
    }
}

.table-container {
    @include max-width(1280px);
}

#compare-table {
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 20px;

    th,
    td {
        overflow: hidden;
        padding: 0;
        vertical-align: middle;
        line-height: 32px;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    .attribute-row {
        border-bottom: 1px solid $very-light-gray;
    }
    .action {
        display: block;
        padding: 0.5em 0;
    }
    .remove-link {
        position: absolute;
        top: -8px;
        right: -4px;
        color: $nobel;
        padding: .3rem;
        transition: color .5s ease;
        z-index: 2;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
    .value,
    .product {
        border-left: 1px solid $very-light-gray;
        text-align: center;

        &.first,
        &:first-of-type {
            border-left: 0;
        }
    }

    .value {
        position: relative;
        .value-item {
            &::after {
                content: ", ";
            }
            &:last-child {
                &::after {
                    content: "";
                }
            }
        }
    }

    .product {
        font-weight: normal;
        text-align: center;
        padding: 0 30px;
        overflow: inherit;

        .items-4 &,
        .items-5 &,
        .items-6 & {
            padding: 0 5px;
        }
    }

    .product-container {
        position: relative;
    }
    .product-cta {
        margin-bottom: 20px;

        &.hide-always {
            display: block;
            opacity: 0;

            button {
                pointer-events: none;
                cursor: not-allowed;
            }
        }
    }
    .product-attribute {
        padding-left: 16px;
        font-weight: normal;
    }

    .product-tile {
        .product-name {
            height: auto;
            margin: .2em 0 1em;
            text-transform: uppercase;
        }
        .swatch-list {
            display: inline-block;
        }

        &::after {
            display: none;
        }
    }
    .product-image {
        height: auto;
        img {
            height: auto;
        }
    }

    .section-header {
        font-size: 0.9em;
        padding: 1em;
        text-transform: none;
    }
    .selectcategory {
        label {
            display: block;
            font-size: 0.9em;
            margin: 1em 0;
            text-transform: none;
        }
        select {
            margin: 0;
            width: 100px;
        }
    }
    .comparelists {
        padding: 0;
    }
    .compare-lists {
        label,
        select {
            float: none;
            text-align: left;
            width: 100%;
        }
    }

    .items-6 & {
        .product-attribute {
            display: none;
        }
    }

    .button-fancy-small {
        z-index: 3;
    }

    @include bp(max-width, $bp-xlarge) {
        .button-fancy-small {
            padding-right: 8px;
            padding-left: 8px;

            &::after {
                display: none;
            }
        }
    }
}
.category-switch-container {
    float: right;
}
