.pt_categorylanding_lifestyle {
    color: $black;

    #main {
        @include bp(min-width, $bp-large) {
            padding: 0 24px;
        }

        @include bp(min-width, $bp-custom-xxlarge) {
            padding: 0 100px;
        }

        .grid-slot-tile {
            display: none;
        }
    }

    .lifestyle-product-list {
        .product-custom-badge {
            display: none;
        }

        .tool-tip-content {
            @include bp(min-width, $bp-large) {
                max-width: 200px;
            }

            &::before {
                @include bp(min-width, $bp-large) {
                    max-width: 200px;
                }
            }
        }
    }

    .grid-two-columns {
        .product-tile {
            .product-name {
                font: normal normal 14px/19px $f-avenir;
            }

            .product-prices {
                font: normal normal 11px/16px $f-avenir;
            }

            .price-type {
                span {
                    display: none;
                }
            }
        }
    }
}

.lifestyle-title {
    color: inherit;
    font: normal normal 26px/31px $f-classical-normal;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: center;
    text-transform: initial;

    @include bp(min-width, $bp-medium) {
        font: normal normal 32px/38px $f-classical-normal;
        margin-top: 40px;
    }

    @include bp(min-width, $bp-large) {
        margin-bottom: 24px;
    }
}

.lifestyle-navigation {
    &__wrapper {
        display: flex;
        font: normal normal 14px/19px $f-avenir;
        justify-content: space-between;
        letter-spacing: 0.01em;
        margin-bottom: 16px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 30px;
        }

        @include bp(min-width, $bp-large) {
            margin-bottom: 20px;
        }
    }

    &__link {
        align-items: center;
        color: inherit;
        display: inline-flex;
        font: inherit;
        overflow: visible;
        padding: 0 8px;
        text-decoration: none;

        &:focus,
        &:hover {
            color: inherit;
            text-decoration: underline;
        }

        @include bp(min-width, $bp-large) {
            padding: 0;
        }
    }

    &__arrow {
        border-right: 1px solid $black;
        border-top: 1px solid $black;
        cursor: pointer;
        height: 8px;
        width: 8px;

        &__left {
            margin-right: 5px;
            transform: rotate(225deg);
        }

        &__right {
            margin-left: 5px;
            transform: rotate(45deg);
        }
    }
}

.lifestyle-main-context {
    &__wrapper {
        @include bp(min-width, $bp-large) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__left {
        @include bp(min-width, $bp-large) {
            margin-top: 40px;
            width: 66%;
        }
    }

    &__right {
        @include bp(min-width, $bp-large) {
            width: 32%;
        }
    }
}

.lifestyle-slot {
    &__wrapper {
        margin-bottom: 40px;

        @include bp(min-width, $bp-large) {
            margin-bottom: 0;
        }
    }

    &__accordion {
        display: flex;
        flex-direction: column;
        padding: 0 32px;

        @include bp(min-width, $bp-large) {
            padding: 0;
        }

        &.collapsed {
            .lifestyle-slot {
                &__about {
                    &::after {
                        top: 35%;
                        transform: rotate(135deg);
                    }
                }

                &__description,
                &__button {
                    display: none;

                    @include bp(min-width, $bp-large) {
                        display: flex;
                    }
                }
            }
        }
    }

    &__about {
        align-self: center;
        color: inherit;
        cursor: pointer;
        font: normal normal 16px/22px $f-avenir;
        padding-right: 16px;
        position: relative;
        text-transform: initial;

        @include bp(min-width, $bp-medium) {
            font: normal normal 20px/28px $f-avenir;
        }

        @include bp(min-width, $bp-large) {
            align-self: initial;
            cursor: initial;
            padding-right: 0;
        }

        &::after {
            border-right: 1px solid $black;
            border-top: 1px solid $black;
            content: "";
            height: 7.15px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: rotate(315deg);
            width: 7.15px;

            @include bp(min-width, $bp-large) {
                display: none;
            }
        }
    }

    &__description {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.01em;
        margin-bottom: 20px;

        @include bp(min-width, $bp-medium) {
            margin-top: 20px;
        }

        @include bp(min-width, $bp-large) {
            margin-bottom: 30px;
        }
    }

    &__button {
        color: inherit;
        border-color: $black;
        display: flex;
        justify-content: center;
        padding: 8px;
        text-transform: initial;

        @include bp(min-width, $bp-medium) {
            align-self: center;
            max-width: 280px;
        }

        @include bp(min-width, $bp-large) {
            align-self: initial;
            max-width: 310px;
        }

        &:focus,
        &:hover {
            background: $black;
        }
    }
}

.lifestyle-slot-carousel {
    &__wrapper {
        margin-bottom: 20px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 30px;
        }

        @include bp(min-width, $bp-large) {
            margin-bottom: 24px;
        }
    }

    &__controls {
        align-items: center;
        display: none;
        font: normal normal 14px/19px $f-avenir;
        justify-content: center;
        letter-spacing: 0.01em;
        margin-top: 20px;

        @include bp(min-width, $bp-large) {
            display: flex;
        }
    }

    &__control {
        border-right: 1px solid $black;
        border-top: 1px solid $black;
        cursor: pointer;
        height: 8px;
        width: 8px;

        &__prev {
            transform: rotate(225deg);
        }

        &__next {
            transform: rotate(45deg);
        }
    }

    &__counter {
        margin: 0 8px;
    }

    .owl-controls {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);

        @include bp(min-width, $bp-medium) {
            bottom: 20px;
        }
    }

    .owl-nav {
        display: none;
    }

    .owl-dots {
        .owl-dot {
            span {
                background: $white;
                border: 1px solid $tile-carousel-dot-border-color;
                height: 8px;
                margin: 0 5px;
                width: 8px;
            }

            &.active {
                span {
                    background: $tile-carousel-dot-active-background;
                }
            }
        }
    }
}

.lifestyle-product-list {
    &__wrapper {
        padding: 0 32px;

        @include bp(min-width, $bp-medium) {
            padding: 0 56px;
        }

        @include bp(min-width, $bp-large) {
            padding: 0;
        }
    }

    &__head {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        @include bp(min-width, $bp-large) {
            margin-bottom: 12px;
        }
    }

    &__title {
        font: normal normal 20px/28px $f-avenir;
        letter-spacing: 0.01em;
    }

    &__body {
        @include bp(min-width, $bp-large) {
            max-height: 100vh;
            overflow-y: auto;
        }
    }

    .tct {
        &__wrapper,
        &__one-column,
        &__two-columns {
            @include bp(min-width, $bp-large) {
                display: flex;
            }
        }
    }

    &.grid-one-column {
        .grid-tile {
            width: 100%;

            @include bpmm($bp-medium, $bp-large) {
                width: 50%;
            }
        }
    }

    &.grid-two-columns {
        .grid-tile {
            width: 50%;
        }
    }

    .search-result-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -14px;
        margin-right: 0;
        padding: 0;

        @include bp(min-width, $bp-medium) {
            margin-left: -20px;
        }

        .grid-tile {
            float: initial;
            margin-bottom: 0;
            padding-right: 0;
            padding-left: 14px;

            @include bp(min-width, $bp-medium) {
                padding-left: 20px;
            }
        }
    }

    .product-image {
        &:hover  {
            .btn-quickview {
                display: none;
            }
        }
    }

    .affirm-as-low-as,
    .affirm-promo-empty {
        display: none;
    }
}
