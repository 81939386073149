.product-tile {
    @extend %clearfix;
    position: relative;
    text-align: center;
    padding: 10px 0 11px;
    margin-bottom: 10px;
    z-index: 0;
    cursor: pointer;

    .product-price img.club-price-tooltip {
        margin-bottom: 4px;
    }

    img {
        max-width: 100%;
        margin: 0 auto;
    }

    .product-custom-badge {
        left: 10px;
        margin: 0;
        max-width: 100%;
        opacity: 1;
        position: absolute;
        top: 10px;
        z-index: 9;

        @include bp(min-width, $bp-medium) {
            left: 15px;
            top: 15px;
        }

        &.hidden {
            opacity: 0;
        }
    }

    .product-image {
        background-color: $white;
        height: auto;
        position: relative;
        padding: 10px;
        margin-bottom: 2px;

        @include bp(min-width, $bp-large) {
            padding: 10px 0;
        }

        .default-image {
            display: block;

            &:not(.loaded) {
                width: 100%;
            }
        }

        .alt-image {
            display: none;

            @include bp(max-width, $bp-large) {
                display: block !important; // override inline css for carousel
            }
        }
    }

    .product-name {
        margin: 0;

        .name-link {
            font: inherit;
            font-size: 14px;
            color: inherit;
            letter-spacing: inherit;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    .wishlist {
        margin: 0 15px 0 8px;
        min-width: 18px;
        height: 25px;
        width: 18px;
        z-index: 1;
    }

    .product-availability {
        margin: 0;
        padding-top: 5px;
        font: normal normal 12px/12px $f-classical;
        color: $brand-primary-dark;
        text-transform: capitalize;
    }

    .product-multi-config-options {
        @include position(absolute, null null 0 0);
        margin: 0;
        width: 100%;

        span {
            background-color: $brand-primary-dark;
            font-size: 11px;
            color: $white;
            padding: 4px 10px;
        }
    }

    .starting-price-text {
        font: normal normal 10px/14px $f-avenir;

        @include bp(min-width, $bp-medium) {
            font: normal normal 12px/16px $f-avenir;
        }
    }

    .product-pricing {
        align-items:  flex-start;
        display: flex;
        padding-bottom: 5px;

        &.logged-in {
            font-weight: 100;
            font-size: $f-size-xxs;

            &.product-club-pricing,
            &.club_price {
                text-decoration: none;
                font-weight: 700;
                font-size: $f-size-s;

                & span,
                & label {
                    text-decoration: none;
                }
            }
        }

        .is-active {
            color: $clr_sale;
        }
    }

    .is-on-sale {
        position: relative;
        display: inline-block;

        &:not(.price-type) {
            &::after {
                border-top: 1px solid $clr_brand_black;
                content: "";
                display: block;
                @include position(absolute, 46% 5px null 5px);
            }
        }
    }

    .product-swatches-all {
        cursor: pointer;
        font-size: 0.9em;
    }
    .product-promo {
        font-size: 0.8em;
        margin: 0.5em 0;
        text-transform: uppercase;

        &.empty {
            display: none;
        }
    }
    .product-compare {
        position: relative;
        overflow: hidden;
        font: normal normal 12px/12px $f-neue;
        color: $brand-primary-light;

        .compare-check {
            @include position(absolute, 0 0 0 -242px);
        }

        label {
            cursor: pointer;

            &::before {
                @extend %icon-font;
                font-size: 12px;
                content: $icon-compare;
                color: $brand-primary-light;
                font-size: 8px;
                line-height: 8px;
                padding-right: 5px;
                position: relative;
                top: -1px;
            }
        }

        .compare-check:checked + label::before {
            @extend %icon-font;
            content: $icon-check;
            color: #267334;
            font-size: 8px;
            line-height: 8px;
            padding-right: 5px;
        }
    }
    .product-review {
        margin-bottom: 1em;
        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }
        .rating {
            display: inline-block;
        }
    }
    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element
        .product-image {
            float: left;
            padding-right: 2em;
        }
        .product-name,
        .product-pricing,
        .product-promo,
        .product-swatches,
        .product-review {
            float: left;
            text-align: left;
            width: 65%;
        }

        .product-name {
            height: auto;
        }

        .product-pricing {
            font-size: 1.3em;
        }
        .product-swatches {
            margin-bottom: 1em;
        }
    }

    &::after {
        @include position(absolute, null 10px -11px 10px);
        content: "";
        background-color: $brand-grey;
        height: 1px;
        display: block;
        z-index: 2;

        @include bp(max-width, $bp-medium) {
            @include position(absolute, null 5px -11px 5px);
        }
    }

    .owl-theme .owl-dots .owl-dot {
        span {
            background: $white;
            border: 1px solid $black;
            margin: 5px;
        }

        &.active span {
            background: $black;
        }
    }

    button.add-to-cart {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 1.2px;
        color: $brand-primary-dark;
        background-color: transparent;
        border: none;
        height: auto;
        width: auto;
        padding: 0;
        border-bottom: 1px solid $brand-primary-dark;
    }

    .product-swatches {
        padding-top: 16px;
        min-height: 42px;
        height: 100%;
        .swatch.selected {
            border: 1px solid $dark-sage;
        }
    }
}

.product-swatches {
    padding-top: 3px;

    ul {
        display: flex;
        align-items: baseline;
        list-style: none outside none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        &.swatch-toggle {
            background: none repeat scroll 0 0 $white;
            display: none;
            position: absolute;
        }
    }
    &:hover ul.swatch-toggle {
        display: block;
    }
    li {
        display: inline-block;
        margin-right: 11px;
        font-size: 12px;
        @include bp(max-width, 1440px) {
            margin-right: 8px;
        }
        @include bp(max-width, 1164px) {
            margin-right: 5px;
        }
        @include bp(max-width, $bp-large - 1) {
            margin-right: 1px;
        }
        @include bp(max-width, 767px) {
            margin-right: 10px;
        }
        @include bp(max-width, 350px) {
            margin-right: 1px;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    .swatch {
        display: block;
        line-height: 0;
        box-sizing: border-box;
        width: 20px;
        height: 20px;

        &.selected {
            .swatch-image {
                border: 2px solid $white;
            }
        }
    }
    .swatch-image {
        width: 100%;
        height: 100%;
    }
    .additional-swatch-count {
        position: relative;
        top: -5px;
        margin: 0;
    }
    .desktop-swatch,
    .desktop-count {
        @include bp(max-width, $bp-large) {
            display: none;
        }
    }

    .mobile-count {
        @include bp(min-width, $bp-large) {
            display: none;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
    color: transparent;
}

div.customization-badging {
    font-family: $f-neue-bold;
    font-size: 13px;
    max-width: fit-content;

    &.hidden {
        opacity: 0;
    }
}

.cc-price-tool-tip {
    display: none;
}
