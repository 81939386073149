$pdp-v2-right-section-width: 325px;

.search-result-items {
    .product-image {
        &:hover  {
            .btn-quickview {
                @include bp(min-width, $bp-large) {
                    display: inline-block;
                }
            }
        }
    }
}

.btn-quickview {
    bottom: 40px;
    position: absolute;
    right: 40px;
}


.ui-dialog {
    &.ui-dialog-quickview {
        border: 1px solid $black;
        color: $black;
        overflow: initial;

        .ui-widget-header {
            height: 0;
            margin: 0;
            padding: 0;
        }

        .ui-dialog-titlebar-close {
            height: 25px;
            opacity: 1;
            position: absolute;
            right: 50px;
            top: 40px;
            transform: rotate(45deg);
            width: 25px;
            z-index: 1;

            &::before,
            &::after {
                content: "";
                position: absolute;
                background: $black;
            }

            &::before {
                height: 1px;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &::after {
                width: 1px;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            span {
                height: 0;
                margin: 0;
                padding: 0;
                width: 0;
            }
        }

        .quickview-content {
            color: inherit;
            overflow: initial;
            padding: 30px;
        }

        h1 {
            margin: 20px 0;
            padding-right: 50px;
        }

        h2,
        h3 {
            margin: 0;
        }
    }
}

.ui-dialog-quickview {
    .quickview-nav,
    .quick-view-navigation,
    .breadcrumb,
    .mobile-name-wrapper,
    .product-number,
    .product-actions,
    .delivery-information-messaging,
    .shipping-info,
    .estimated-shipping,
    .zoom-expand,
    .link-to-details,
    .heading-print,
    .image-print,
    .print-footer,
    .print-content {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.swatches {
            transition: 0.75s;
            position: relative;
            padding-top: 10px;
        }
    }

    .ui-dialog-title {
        display: none;
    }

    .product-detail-container,
    .product-main-container {
        display: flex;
        width: 100%;
    }

    .product-col-1,
    .product-detail-img {
        margin-right: 20px;
        max-width: 475px;
        width: 100%;
    }

    .product-col-2,
    .product-detail-content {
        display: flex;
        flex: 1;
        flex-direction: column;

        .price-value {
            display: flex;
            flex-flow: row;
        }

        .swatches:not(.box-variant) {
            .swatchanchor {
                border-radius: 50%;

                &::before {
                    border-radius: 50%;
                }
            }
        }

        .box-variant {
            li {
                min-width: 80px;
                padding: 0;
                width: auto;

                .swatchanchor {
                    padding: 0 5px;
                }

                &:not(.selected) {
                    .swatchanchor {
                        &::before {
                            border-color: $brand-v2-medlt-gray;
                        }
                    }
                }
            }
        }

    }

    .product-name {
        text-transform: initial;
    }

    .product-price-non-member {
        order: -1;
    }

    .add-to-cart-section {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .product-image-container {
        width: 100%;
    }

    .product-addtocart-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;

        .content {
            display: flex;
            flex: 1;
            flex-direction: column;

            .promotion {
                display: inline-block;
            }
        }

        .pdpForm {
            clear: both;
            margin-top: auto;
        }
    }

    .promotion {
        padding: 10px 0;

        .promotion-title {
            color: $california-gold;
            display: none;
            margin-bottom: 2px;
            font: 600 normal 13px/19px $f-classical;
            letter-spacing: 0.05em;
            width: 100%;

            &:last-of-type {
                display: block;
                order: -1;
            }
        }

        .promotion-details {
            width: 100%;
        }

        .promotion-callout {
            display: flex;
            flex-wrap: wrap;
        }

        .promotion-callout,
        .tooltipstered {
            color: $brand-v2-off-black;
            font: normal normal 13px/19px $f-primary;
        }
    }

    .main-image {
        cursor: default;
        display: block;
        text-align: center;
        position: relative;
        overflow: visible;

        img {
            max-width: 100%;
            vertical-align: bottom;
            width: 100%;
        }

        &[href=""] {
            pointer-events: none;
        }
    }

    .product-thumbnails {
        margin-top: 20px;
        padding: 0 50px;
        visibility: hidden;

        .owl-nav {
            margin: 0;
        }

        .owl-prev,
        .owl-next {
            background: transparent;
            border-left: 1px solid $dark-sage;
            border-radius: 0;
            border-top: 1px solid $dark-sage;
            height: 14px;
            margin: 0;
            opacity: 1;
            padding: 0;
            position: absolute;
            text-indent: -9999px;
            top: 50%;
            width: 14px;

            &:hover {
                background: transparent;
            }

            &.disabled {
                visibility: hidden;
            }
        }

        .owl-prev {
            left: -25px;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        .owl-next {
            right: -35px;
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }

    .owl-stage-outer {
        padding-bottom: 2px;
    }

    .product-price {
        margin-bottom: 20px;
    }

    .starting-price-text {
        color: $qv-start-price-color;
        display: block;
        font: normal normal 14px/19px $f-avenir;
        margin-bottom: 5px;
    }

    .price-standard {
        padding-right: 10px;

        &.line-through {
            text-decoration: line-through;
        }
    }

    .price-sales {
        color: $hot-hands;
        padding-right: 10px;
    }

    .pricing-reg-club {
        display: flex;
        flex-direction: column;
    }

    .product-club-pricing {
        padding-top: 4px;
        position: relative;
        max-width: fit-content;
    }

    .club-price-tooltip {
        cursor: pointer;
        margin-left: 3px;
        width: 12px;
        vertical-align: baseline;
    }

    .club-pricing-tooltip {
        display: none;
        position: absolute;
        background: $brand-v2-pink-cream;
        padding: 20px;
        margin: 8px 6px;
        z-index: 100;
        text-transform: none;
        top: 23px;
        right: -68px;
        width: 280px;
        border: 1px solid $brand-v2-lt-gray;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        white-space: normal;
        @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);

        p,
        strong,
        a {
            @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);
            color: $brand-v2-off-black;
        }

        &::before {
            content: "";
            display: none;
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid transparent;
            border-bottom: 8px solid #4d4c4a;
            border-left: 8px solid transparent;
            right: 60px;
            top: -14px;
            border-right: 8px solid transparent;
        }

        &::after {
            content: " ";
            display: inline-block;
            position: absolute;
            width: 14px;
            height: 14px;
            background: $brand-v2-pink-cream;
            border: 1px solid $brand-v2-lt-gray;
            border-width: 1px 0 0 1px;
            transform: rotate(45deg);
            right: 60px;
            top: -7px;
            z-index: 10;
        }
    }

    .product-price-top-label {
        font: normal normal 16px/22px $f-primary;
        display: inline-block;
        margin-bottom: 5px;
    }

    .product-variations {
        border: 1px solid $brand-light-grey-2;
        background: $brand-v2-white;
        max-width: 495px;
        padding: 5px 25px 0 15px;
        margin-bottom: 15px;

        .attribute:not(:last-child) {
            border-bottom: 1px solid $brand-light-grey-2;
        }

        .name {
            &.unselected {
                color: $c-error;
                margin-left: 5px;
            }
        }
    }

    .product-variations,
    .product-options {
        ul {
            @extend %clearfix;
        }

        .attribute {
            text-indent: 0;
            display: block;
            padding: 20px 0 15px;

            &.size,
            &.altseatdepth {
                padding-top: 10px;
            }

            .label {
                font: normal normal 14px/18px $f-neue-bold;
                text-transform: capitalize;
                color: $brand-v2-off-black;
                margin-bottom: 4px;

                &.selected {
                    font: normal 14px/18px $f-neue;
                    color: $brand-v2-off-black;
                }

                &.mto-color {
                    color: $c-blue;
                }

                .value {
                    text-transform: none;

                    &::after {
                        display: none;
                    }

                    span {
                        margin-right: 11px;
                        line-height: 2px;
                    }
                }
            }

            .perpetual + .label {
                margin-top: 25px;
            }

            .selected-value {
                border: 0 none;
                color: $citrus;
                font-weight: bold;
                padding: 0.8em 0 0 0.4em;
                text-transform: uppercase;
            }

            .size-chart-link {
                clear: both;
                float: none;
                a {
                    border-color: $white !important;
                    padding: 0;
                }
            }

            @include bp(min-width, $bp-custom-large) {
                .choices {
                    width: 100%;
                    max-width: 450px;
                }
            }
        }

        .swatches {
            display: flex;
            flex-wrap: wrap;
            margin-left: -7px;
            margin-right: -7px;

            .swatch-overlay {
                display: none;
                white-space: nowrap;
                position: absolute;
                background-color: $white;
                border: 1px solid $brand-v2-lt-gray;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
                padding: 15px;
                z-index: 10000;
                line-height: 1.2;
                font-family: $f-primary;

                &.active {
                    display: inline-block;
                    top: 50%;
                    right: calc(100% + 10px);
                    transform: translate(0, -50%);
                }
            }

            .fabric-name {
                color: $dark-sage;
                display: none;
                font: 14px/20px $f-neue;
                margin-left: 8px;
                margin-top: -24px;
                position: absolute;

                &.selected,
                &.unselected {
                    display: block;
                }

                &.unselected {
                    .name {
                        color: $c-error;
                    }
                }

                .name {
                    margin-left: 5px;
                }
            }

            .mobile-fabric-detail {
                display: none;
                position: absolute;
                margin-top: 20px;
                font-family: $f-primary;
                color: $charcoal;
                z-index: 1;
                font-size: 12px;
                line-height: 1.5;
                padding-top: 15px;
                padding-bottom: 15px;
                background: $white;

                .name {
                    font-weight: bold;
                    color: $charcoal;
                    padding-bottom: 10px;
                    font-size: 13px;
                    padding-left: 15px;
                }

                .description {
                    padding-top: 15px;
                    font-family: $f-primary;
                    color: $charcoal;
                    font-size: 12px;
                    line-height: 1.5;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .fabric-mobile-arrow {
                    position: absolute;
                    top: -15px;
                    width: 0;
                    height: 0;
                    border: 12px solid transparent;
                    border-top-width: 0;
                    border-bottom-width: 15px;

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -11px;
                        top: 1px;
                        border: 11px solid transparent;
                        border-top-width: 0;
                        border-bottom-width: 15px;
                    }
                }
            }

            li {
                text-indent: 0;
                margin: 0 7px 7px;
                width: 40px;
                height: 40px;
                padding: 1.5px;

                &.swatch-header {
                    font: normal normal 10px/18px $f-avenir;
                    letter-spacing: 1px;
                    margin: 10px 0 5px;
                    padding: 0;
                    width: 100%;
                }

                &.selected-value {
                    display: none;
                }

                a {
                    display: block;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    line-height: 42px;
                    text-align: center;
                    white-space: nowrap;
                    background: $white;
                    color: $brand-primary-dark;
                    @extend %anchor-no-decoration;

                    &::before {
                        @include position(absolute, 0 0 0 0);
                        display: block;
                        content: "";
                        border: 1px solid $brand-grey;
                    }

                    .has-image & {
                        width: percentage(30/288);
                        height: auto;
                        line-height: 32px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        vertical-align: top;
                    }
                }

                &.text-small {
                    font-size: 11px;
                }

                &.has-image a::before {
                    display: none;
                }

                &.selected {
                    padding: 0;

                    a {
                        line-height: 40px;

                        &::before {
                            display: block;
                            border: 1px solid $brand-v2-off-black;
                            box-shadow: inset 0 0 0 2px $white;
                        }
                    }
                }

                &.unselectable,
                &.ssipsop-unavailable {
                    background: linear-gradient(
                        to top left,
                        transparent calc(50% - 1px),
                        $black-two,
                        transparent calc(50% + 1px)
                    );
                    padding: 0;

                    a {
                        opacity: 0.5;

                        &::before {
                            display: block;
                            border: 1px solid $very-light-gray;
                            box-shadow: inset 0 0 0 2px $white;
                        }
                    }
                }
            }
        }

        select {
            background-color: $white;
            border: 1px solid $gainsboro;
            border-radius: 0;
            height: 32px;
            width: 100%;
            margin-left: 0;
        }
    }

    .product-primary-image {
        position: relative;

        &.show-video {
            flex: 1;

            .main-image,
            .pinch-zoom-container {
                display: none;
            }

            .product-video {
                display: block;
            }
        }

        &.show-image {
            .product-video {
                display: none;
            }
        }

        &.zoom-on-mobile-loaded {
            .primary-image {
                opacity: 0;
            }

            .pinch-zoom-media-item {
                opacity: 1;
            }
        }
    }

    .zoomImg {
        &.zoom-in {
            transition: opacity 0.3s linear;
        }
    }

    .pinch-zoom-container {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        @include bp(min-width, $bp-large) {
            display: none;
        }
    }

    .pinch-zoom-media-item-wrap {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @include bp(min-width, $bp-large) {
            display: none;
        }
    }

    .pinch-zoom-media-item {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    .variant-dropdown {
        .ba-select-container.sm {
            width: 250px;
        }
    }

    .ba-select-container.sm {
        width: 98px;

        .ba-options {
            border: 1px solid $very-light-gray;
            max-height: 45px;
            padding: 8px 35px 10px 25px;

            &::after {
                border: none;
            }

            ul {
                min-height: 45px;
                li {
                    font: 18px/28px $f-neue !important;
                    letter-spacing: 0;
                }
            }

            .ba-arrow {
                top: 15px;
                right: 15px;

                &::before {
                    content: url("../images/arrow-down.svg");
                }
            }
        }

        .ba-shiv {
            border: none;
            height: 45px;
            padding-top: 8px;
            padding-left: 25px;
        }

        .ba-shiv-content {
            font: 18px/28px $f-neue;
            letter-spacing: 0;
        }
    }

    .special-offer {
        margin-bottom: 15px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 0;
        text-align: left;

        a {
            color: $white;
            &:hover {
                color: $light-smoke;
            }
        }
    }

    .product-add-to-cart {
        margin: 20px 0;

        &:not(.add-sub-product) {
            .price-shipping {
                display: none;
            }
        }
    }

    button.add-to-cart {
        font: normal normal 14px/19px $f-avenir;
        flex: 1;
        letter-spacing: 0.01em;
        background: $black;
        border: 1px solid $black;
        color: $white;
        height: 40px;
        margin: 0 5px 0 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-transform: uppercase;

        &:hover {
            background: $white;
            color: $black;
        }

        &:disabled {
            background: $very-light-gray;
            border-color: $very-light-gray;
            color: $dark-sage;
        }

        span {
            border: none;
        }
    }

    .v2 {
        button.add-to-cart {
            margin: 0 10px 0 15px;
        }
    }

    .quickview-details {
        font: normal normal 14px/19px $f-avenir;
        flex: 1;
        letter-spacing: 0.01em;
        background: $white;
        border: 1px solid $black;
        color: $black;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.3s ease;

        &:hover {
            background: $black;
            color: $white;
        }
    }

    .main-ctas {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .inventory {
        white-space: nowrap;

        .select-label {
            margin-bottom: 5px;
        }

        input {
            font-size: 14px;
        }

        .quantity {
            border: 1px solid $very-middle-gray;
            font-family: $f-primary;
            position: relative;
            width: 72px;
            display: flex;
            justify-content: space-evenly;

            &:hover {
                border-color: $brand-v2-off-black;
            }

            &.disabled {
                &:hover {
                    border: 1px solid $very-middle-gray;
                }

                span {
                    &.minus,
                    &.plus {
                        cursor: auto;
                    }
                }
            }

            input {
                font: normal normal 16px/15px $f-avenir;
                border: none;
                color: $black;
                letter-spacing: 0.06em;
            }

            span.minus,
            span.plus {
                border-color: $very-middle-gray;
                color: $black;
                font-size: 14px;
                vertical-align: top;
                display: inline-block;
                height: 38px;
                padding: 0 12px;
                position: relative;
                cursor: pointer;
            }

            span.minus {
                background: url("../images/minus-black.svg") no-repeat center;
                background-size: 9px;
            }

            span.plus {
                background: url("../images/plus-black.svg") no-repeat center;
                background-size: 9px;
            }
        }
    }

    .fullview-details {
        color: inherit;
        display: inline-block;
        font: inherit;
        letter-spacing: 0.06em;
        margin-top: 11px;
        padding-right: 12px;
        position: relative;

        &::after {
            border-right: 1px solid $black;
            border-top: 1px solid $black;
            content: "";
            height: 6px;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 6px;
        }
    }

    .cylindo-drag-tooltip {
        font-size: 0;
        background: transparent url("../images/cylindo-arrows-desk.png") no-repeat 50% 50%;
        width: 205px;
        height: 53px;
        .left,
        .right {
            display: none;
        }
    }

    .cylindo-loading-bar > div {
        background-color: $brand-v2-lt-gray;
        top: 17px;
        position: relative;
    }

    .cylindo-thumbnail-wrapper {
        .glyphicon-menu-left,
        .glyphicon-menu-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &::before {
                content: " ";
                background: url("../images/carousel-prev.svg") no-repeat 0 50%;
                height: 60px;
                width: 30px;
                display: block;
            }
        }

        .glyphicon-menu-right {
            &::before {
                transform: rotate(180deg);
            }
        }

        &.thumb-location-bottom {
            .cylindo-thumbnail-list {
                > li {
                    > img {
                        max-width: 90%;
                    }
                }
            }
        }

        .cylindo-thumbnail-list {
            li {
                &::after,
                &:last-of-type::after {
                    content: " ";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: $brand-v2-off-white;
                    opacity: 0.03;
                    border-radius: 3px;
                    transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
        }
    }

    .cylindo-viewer-container {
        overflow: visible;
    }

    #pdpMain.v2 {
        *:focus {
            outline: 1px solid rgb(85, 85, 85);
        }

        .product-addtocart-wrapper {
            margin-bottom: 14px;

            .request-free-swatches {
                .request-free-swatches-link {
                    justify-content: flex-end;
                }
            }
        }

        .content-asset {
            display: inline;
            height: 100%;
            width: auto;
        }

        .product-price {
            margin-bottom: 0;
        }

        .product-header-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-top: 8px;

            .product-name {
                text-transform: capitalize;
                @include font-source-serif(32px, $brand-v2-dark-gray, normal, 38px, null);
                max-width: 600px;
                text-align: center;
                margin: 26px 0 5px;
            }
        }

        .promotion-callout {
            color: $brand-v2-dark-gray;
            font: normal normal 12px/16px $f-avenir;
            letter-spacing: 0.05em;
        }

        .product-add-to-cart {
            margin: 15px 0 0;
            .add-custom-product-to-cart {
                flex-grow: 1;
            }
            .main-ctas {
                margin-bottom: 14px;
                justify-content: space-between;
            }
        }

        .product-main-container {
            .product-detail-content {
                .line-through {
                    padding-right: 5px;
                }
            }
        }

        .request-free-swatches {
            .request-free-swatches-link {
                @include font-source-serif-italic(13px, $brand-v2-off-black, normal, 15px, 0.05em);
                text-decoration: none;
                position: relative;
                display: flex;
                justify-content: center;

                &:hover {
                    text-decoration: underline;
                }

                &::before {
                    content: " ";
                    background: transparent url("../images/free-swatches.png") no-repeat left top;
                    width: 30px;
                    height: 15px;
                    display: inline-block;
                    background-size: 22px;
                }
            }
        }

        .product-variations,
        .product-options {
            .swatches {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                margin: 0 0 0 5px;
                width: 100%;
                padding: 0;

                li {
                    height: auto;
                    margin: 0 0 5px;
                    box-sizing: content-box;

                    &.swatch-header {
                        margin: 10px 0 5px;
                    }

                    a,
                    button {
                        img {
                            width: 34px;
                            height: 34px;
                            vertical-align: inherit;
                            border-radius: 100%;
                            max-height: none;
                        }
                    }

                    button {
                        background: none;
                        border: 5px solid transparent;
                        border-radius: 100%;
                        display: block;
                        height: 44px;
                        min-width: initial;
                        overflow: hidden;
                        padding: 0;
                        width: 44px;
                    }

                    &.selectable {
                        border: 1px solid transparent;
                        border-radius: 100%;
                    }

                    &.selected {
                        .swatchanchor {
                            line-height: normal;
                            border-radius: 100%;
                            box-shadow: inset 0 0 0 6px $white;
                            border: 1px solid $black;
                            box-sizing: content-box;
                            width: 34px;
                            height: 34px;
                            padding: 5px;
                        }
                    }

                    &.unselectable,
                    &.ssipsop-unavailable {
                        border: 1px solid $brand-v2-white;
                        border-radius: 100%;
                        margin: 5px 5px 10px;
                        position: relative;
                        overflow: hidden;
                        width: 34px;
                        height: 34px;

                        .swatchanchor {
                            opacity: 0.5;
                            height: 34px;
                            width: 34px;
                            border: 0;

                            &::before {
                                display: inline;
                                border: none;
                                box-shadow: none;
                            }
                        }
                        &.selected {
                            .swatchanchor {
                                padding: 0;
                            }
                        }
                    }

                    .swatchanchor {
                        &::before {
                            display: inline-block;
                            border: none;
                        }
                    }
                }

                &.box-variant {
                    margin: 0;

                    li {
                        border: initial;
                        border-radius: initial;
                        height: 40px;
                        margin: 0 7px 7px;
                        min-width: 80px;
                        padding: 0;
                        width: auto;

                        .swatchanchor {
                            border: initial;
                            border-radius: initial;
                            box-sizing: border-box;
                            height: 100%;
                            line-height: 40px;
                            padding: 0 5px;
                            width: 100%;

                            &::before {
                                display: block;
                                border: 1px solid $brand-v2-off-black;
                                box-shadow: inset 0 0 0 2px $white;
                            }
                        }

                        &:not(.selected) {
                            .swatchanchor {
                                &::before {
                                    border-color: $brand-v2-medlt-gray;
                                }
                            }
                        }
                    }
                }
            }
        }

        .swatch-detail-overlay,
        .product-customization-selected-option-details {
            display: none;
            width: 300px;
            background-color: $white;
            border: 1px solid $brand-v2-lt-gray;
            box-sizing: border-box;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            cursor: auto;
            z-index: 29;
            transform: translate(-96%, -55%);

            &.swatch-variant {
                margin-top: 18px;
            }

            .description,
            .overlay-details {
                font-size: 12px;
                margin-bottom: 0;
                width: 100%;
            }

            &.active {
                display: block;
            }

            .img {
                border-radius: 10px;
                position: relative;
                width: 100%;

                &::before {
                    @include bp(max-width, $bp-large) {
                        background: url("../images/magnifier.svg") center no-repeat;
                        background-color: rgba(249, 248, 244, 0.9);
                        border-radius: 50%;
                        content: "";
                        display: block;
                        height: 25px;
                        position: absolute;
                        width: 25px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                img {
                    width: 100%;
                    border-radius: 10px;

                    &.lazy {
                        height: 80px;
                    }
                }
            }

            .swatch-description {
                padding: 0;
            }
        }

        .additional-variants {
            @include font-source-sans(12px, $brand-v2-off-black, normal, 17px, 0.03em);
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }

        .additional-variant-name {
            @include font-source-serif-italic(13px, $brand-v2-dark-gray, normal, 19px, 0.03em);
            width: 100%;

            @include bp(max-width, $bp-large) {
                @include font-source-serif(13px, $brand-v2-dark-gray, normal, 19px, 0.03em);
            }
        }

        .additional-variant-value {
            width: 100%;
        }

        .product-customization-selected-option-details {
            padding: 0;
        }

        .swatch-detail-overlay {
            position: absolute;
            top: 345px;

            .heading {
                font: normal italic 14px/17px $f-classical;
                letter-spacing: 0.05em;
                margin-bottom: 5px;
            }

            .description {
                font: normal normal 12px/16px $f-avenir;
                margin-bottom: 10px;
            }

            &.swatch-variant.m-show {
                position: absolute;
                display: block;
                top: 175px;
                padding: 18px;
                left: -28px;

                .additional-variants-details {
                    display: block;
                }
            }
        }

        .customization-swatch-name {
            @include font-source-sans(12px, $brand-v2-off-black, normal, 18px, 0.03em);
        }

        .swatch-description {
            padding: 0;
        }

        .show-toltip {
            position: relative;

            .swatch-detail-overlay.swatch-variant.m-show {
                top: 0;
                max-width: 300px;
                width: auto;
                box-sizing: content-box;
                white-space: nowrap;
            }
        }

        .product-quickship-container {
            padding: 0 7px;

            .choices {
                padding-top: 8px;
            }
        }

        // buttons to start customizing or resume customizing

        div.product-variations {
            border-radius: 10px;
            border: 1px solid $brand-v2-lt-gray;
            padding: 23px 22px 20px;
            text-transform: none;
            background: $brand-v2-white;
            width: 100%;
            min-height: 155px;
            height: auto;
            text-align: left;
            transition: none;
            clear: both;
            @include font-source-sans(12px, $brand-v2-off-black, normal, 18px, 0.05em);

            &:hover {
                background: $brand-v2-pink-cream;
            }
        }

        .product-variations {
            height: auto !important;
            margin-bottom: 15px;
            padding: 23px 15px 0 !important;

            &:hover {
                background: $brand-v2-white !important;
            }

            .select-label.size {
                display: none;
            }

            .attribute {
                border: 0 none;
                padding: 0;

                &.size {
                    margin-top: 5px;
                }
            }

            .product-quickship-header {
                position: relative;
                left: 10px;
            }

            .variation-label,
            .select-label.va-feature {
                @include font-source-sans(12px, $brand-v2-off-black, normal, 19px, 0.05em);
                margin: 0 7px;
                padding: 10px 0 7px;
                border-top: 1px solid $brand-v2-lt-gray;
                text-transform: capitalize;
                display: flex;

                .name {
                    @include font-source-serif-italic(12px, $brand-v2-dark-gray, normal, 20px, 0.05em);
                    margin-left: 5px;
                }
            }

            .select-label.va-feature {
                display: block;
            }

            // variant display quickship swatches

            .choices {
                width: 100%;
                max-width: none;

                &.imageSwatchAttrsection {
                    padding-top: 5px;
                }
                // new custom select
                .input-box.ba-select-container {
                    width: 100% !important;

                    .ba-shiv {
                        border: none;
                        box-shadow: none;
                        padding-top: 15px;
                        background: $brand-v2-white;
                    }

                    .ba-arrow {
                        top: 18px;
                        right: 10px;
                        background: $brand-v2-white;

                        &::before {
                            content: "";
                            width: 24px;
                            height: 14px;
                            background: url("../images/arrow-down-v2.svg") no-repeat;
                            background-size: cover;
                        }
                    }

                    .ba-select.open {
                        .ba-options {
                            background: $brand-v2-white;
                            border: 1px solid $brand-v2-lt-gray;
                            border-radius: 10px;
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                        }
                    }

                    .ba-shiv-content {
                        @include font-source-sans(12px, $brand-v2-off-black, normal, 19px, 0.05em);
                    }

                    .ba-options {
                        border: none;
                        background: transparent;

                        &::before {
                            background: $brand-v2-white;
                        }

                        ul {
                            background: $brand-v2-white;

                            li {
                                //override the ba important declarations with more important declarations
                                font-family: $f-primary !important;
                                font-size: 12px !important;
                                color: $brand-v2-off-black !important;
                                font-weight: normal !important;
                                line-height: 19px !important;
                                letter-spacing: 0.05em !important;
                                padding: 6px;

                                &:hover {
                                    border-bottom: 1px solid $brand-v2-lt-gray;
                                }

                                &:last-child:hover {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-customization {
            position: relative;
        }

        .download-spec-sheet {
            @include font-source-serif-italic(13px, $brand-v2-dark-gray, normal, 16px, 0.05em);
            background: transparent url("../images/download.svg") no-repeat top center;
            text-align: center;
            padding-top: 25px;
        }

        .tool-tip,
        .equal-pay-link,
        .tool-tip-return-to-quickship {
            position: relative;

            .icon,
            .fa-envelope-square {
                display: inline-block;
                width: 15px;
                height: 15px;

                &::before {
                    content: " ";
                    display: block;
                    width: 15px;
                    height: 30px;
                    background: url("../images/details_icon.svg") left top no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0.4;
                }
            }

            .tool-tip-content,
            .tool-tip-content-option {
                display: none;
            }

            &:hover,
            &:focus,
            &.tooltip-hover {
                .icon::before,
                .fa-envelope-square::before {
                    opacity: 1;
                }
                .icon::after {
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    background: $brand-v2-pink-cream;
                    border: 1px solid $brand-v2-lt-gray;
                    border-width: 1px 0 0 1px;
                    transform: rotate(45deg);
                    top: 28px;
                    z-index: 31;
                }
                .tool-tip-content {
                    position: absolute;
                    display: block;
                    background: $brand-v2-pink-cream;
                    padding: 20px;
                    width: 320px;
                    z-index: 30;
                    color: $brand-v2-off-black;
                    top: 34px;
                    border: 1px solid $brand-v2-lt-gray;
                    border-radius: 10px;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                    @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);
                    white-space: normal;

                    p,
                    strong {
                        @include font-source-sans(12px, $brand-v2-off-black, 400, 18px, 0.05em);
                    }
                    &::before {
                        content: "";
                        height: 30px;
                        width: 320px;
                        position: absolute;
                        left: 0;
                        top: -28px;
                    }
                }
            }
            &.option-tool-tip {
                .icon::after {
                    display: none;
                }
            }
        }

        .tool-tip-return-to-quickship {
            .icon {
                &::before {
                    background: url("../images/arrow_tooltip.svg") left 14px no-repeat;
                    width: 16px;
                    margin: -13px 0 0;
                }
            }
            &:hover,
            &:focus {
                .icon {
                    &::after {
                        top: -19px;
                        border-width: 0 1px 1px 0;
                    }
                }

                .tool-tip-content {
                    top: auto;
                    right: -10px;
                    bottom: 34px;
                    padding: 10px 28px;
                    text-align: center;

                    &::before {
                        bottom: -28px;
                        top: auto;
                    }
                }
            }
        }

        .customization-tooltip-content,
        .tool-tip,
        .equal-pay-link {
            .center {
                text-align: center;
            }
            p {
                font-size: 12px;
                line-height: 18px;
            }
            .request-free-swatches-link,
            .request-complimentary-link {
                @include font-source-serif-italic(12px, $brand-v2-off-black, normal, 18px, 0.05em);
                text-decoration: none;
                position: relative;
                display: flex;
                justify-content: center;
                flex-flow: column;
                align-items: center;
                padding-bottom: 18px;

                &:hover {
                    text-decoration: underline;
                }

                &::before {
                    content: " ";
                    background: transparent url("../images/free-swatches.png") no-repeat center top;
                    height: 26px;
                    display: block;
                    width: 100%;
                    background-size: 22px;
                }
            }
            .request-complimentary-link {
                max-width: 74%;
                text-align: center;
                margin: 0 auto;
                padding-top: 29px;

                &::before {
                    padding-top: 35px;
                    width: 26px;
                    background: transparent url("../images/complimentary.svg") no-repeat center top;
                }

                &::after {
                    content: " ";
                    border-top: 1px solid $brand-v2-lt-gray;
                    position: absolute;
                    width: 100px;
                    top: 0;
                }
            }
        }

        .font-size-12 {
            @include font-source-sans(12px, $brand-v2-off-black, normal, 26px, 0.05em);
        }

        .font-size-16 {
            @include font-source-sans(16px, $brand-v2-off-black, normal, 26px, 0.05em);
        }

        .font-size-18 {
            @include font-source-serif(18px, $brand-v2-off-black, normal, 26px, 0.05em);
            text-transform: none;
        }
    }

    .thumb {
        border: 1px solid $white;
        height: 72px;
        width: 72px;

        &.thumb-video {
            &:hover {
                .thumb-video-play {
                    opacity: 1;
                }
            }

            .thumbnail-link {
                position: relative;
            }

            .productthumbnail {
                height: auto;
                max-width: 100%;
                min-height: initial;
                min-width: initial;
                padding: 15px 0;
                width: auto;
            }
        }

        .thumb-video-play {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.75;
        }

        .no-carousel & {
            &:first-child {
                margin-left: 0;
            }
        }

        &.selected {
            .thumbnail-link {
                border: 1px solid $coal;
            }
        }

        a {
            display: block;
        }

        .productthumbnail {
            min-height: 70px;
            height: 70px;
            min-width: 70px;
            width: 70px;
        }
    }

    .cross-sell-link-wrapper {
        color: $brand-v2-off-black;
        display: flex;
        font: normal normal 10px/18px $f-avenir;
        justify-content: flex-end;
        letter-spacing: 1px;
        margin-bottom: 10px;

        a {
            border-bottom: 1px solid $brand-v2-off-black;
            color: inherit;
            font: inherit;
            text-decoration: none;

            &:focus {
                color: inherit;
            }
        }
    }

    .product-video-player-temp-image {
        opacity: 0;
        width: 100%;
    }

    .product-video-player,
    .cylindo-video-youtube,
    .cylindo-video-vimeo {
        pointer-events: none;
    }
}

.product-quickship-headline {
    display: block;
    margin-bottom: 12px;
    position: relative;
    @include font-source-sans(16px, $brand-v2-off-black, normal, 21px, 0.03em);
}

.product-quickship-shipsin {
    margin-bottom: 13px;
    @include font-source-serif-italic(13px, $brand-v2-dark-gray, normal, 16px, 0.05em);
}

.qv-vd {
    &__wrapper {
        margin-top: 20px;
    }

    &__head {
        display: flex;
        border-bottom: 1px solid $brand-v2-lt-gray;
    }

    &__tab {
        cursor: pointer;
        display: flex;
        flex: 1;
        font: normal normal 16px/19px $f-classical-normal;
        justify-content: center;
        letter-spacing: 0.01em;
        padding-bottom: 12px;
        position: relative;

        &::after {
            background: $brand-v2-off-black;
            bottom: -1px;
            content: "";
            display: none;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 40px;
        }
    }

    &__body {
        max-height: 261px;
        overflow-y: auto;
        padding-top: 20px;
    }

    &__tab-panel {
        display: none;
    }

    &__tab-panel-design {
        font: normal normal 14px/19px $f-avenir;
        letter-spacing: 0.01em;

        .product-heading {
            font: normal normal 18px/26px $f-avenir;
            letter-spacing: initial;
            text-align: center;
        }

        .tab-column-1 {
            margin-top: 11px;
        }

        ul {
            font: inherit;
            list-style: outside;
            padding-left: 18px;
        }
    }

    &[data-active-tab="active-options"] {
        .qv-vd {
            &__tab-options {
                &::after {
                    display: block;
                }
            }

            &__tab-panel-options {
                display: block;
            }
        }
    }

    &[data-active-tab="active-design"] {
        .qv-vd {
            &__tab-design {
                &::after {
                    display: block;
                }
            }

            &__tab-panel-design {
                display: block;
            }
        }
    }
}

.gtm-qv {
    display: none;
}
