$tile-width: 295px;
$tile-margin: 30px;
$carousel-bp: 1127px;

.carousel-refresh {
    background: $carousel-background;
    text-align: center;
    max-width: none;
    padding: 0;
    margin: 40px 0;

    @include bp(max-width, $carousel-bp) {
        margin: 30px 0;
    }

    h2 {
        padding: 0 0 15px;
        margin: 35px 0;
        border: none;

        @include bp(max-width, $carousel-bp) {
            margin: 30px 0 28px;
        }
    }

    .tiles-container,
    .carousel-inner {
        max-width: calc((#{$tile-width} + #{$tile-margin}) * 4);
        margin: 0 auto;
        padding: 0;

        @include bp(max-width, $carousel-bp) {
            padding: 0 10px;
        }

        img {
            max-width: 100%;
        }
    }

    .product-carousel,
    #predictive-recommendations,
    .predictive-recommendations {
        max-width: calc(100% - 170px);
        margin: 0 auto;

        @include bp(max-width, $carousel-bp) {
            max-width: none;
            padding: 0;
        }
    }

    .owl-stage {
        display: flex;
        margin: 0 auto;
    }

    .owl-item,
    .carousel-item,
    .grid-tile,
    .product-tile {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        align-items: center;
    }

    .carousel-item,
    .grid-tile {
        align-items: center;
    }

    .product-tile {
        background: $white;
        max-width: $tile-width;
        margin: 0 15px;
        padding: 10px;
        text-align: left;

        @include bp(max-width, $carousel-bp) {
            width: calc(50vw - 25px);
            padding: 9px 5px;
            margin: 0 auto;
        }

        .product-image {
            padding: 10px;

            @include bp(max-width, $carousel-bp) {
                padding: 5px;
            }
        }

        .product-info {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            flex-grow: 1;
        }

        .product-custom-badge {
            display: none;
        }

        .product-name {
            align-self: flex-start;
            padding: 30px 0 0;
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 26px;

            a {
                @extend .body-large;
                text-transform: uppercase;
            }

            @include bp(max-width, $carousel-bp) {
                padding: 35px 0 6px;
                margin-bottom: 7px;
            }
        }

        .product-prices {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            @include bp(min-width, $bp-large) {
                flex-direction: row-reverse;
            }
        }

        .product-pricing {
            padding-bottom: 0;
        }

        .club-price {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include bp(min-width, $bp-large) {
                flex-direction: row;
            }

            .product-standard-price,
            .product-sales-price {
                padding-bottom: 5px;

                &:not(.price-type) {
                    margin-right: 5px;
                }
            }

            .product-standard-price {
                &.is-on-sale {
                    &:not(.price-type) {
                        text-decoration: line-through;
                    }
                }

                &::after {
                    display: none;
                }
            }

            .price-type {
                text-transform: none;

                &.not-first {
                    display: none;
                }
            }
        }

        .price-type {
            display: block;
        }

        button {
            @include plain-button;

            &.add-to-cart,
            &.add-to-cart-disabled {
                border: none;
                height: auto;
                font: 14px/18px $f-neue-bold;
                letter-spacing: 0.5px;
                text-decoration: none;
                color: $dark-sage;
                padding: 9.5px 26px 8px 0;
            }
            &:focus,
            &:hover {
                color: $dark-sage;
            }
            &::after {
                right: 0;
            }
        }
        .product-swatches,
        &::after {
            display: none;
        }

        .starting-price-text,
        .reg-price,
        .product-promo,
        .affirm-as-low-as,
        .add-to-cart {
            display: none;
        }
    }

    .owl-carousel .owl-stage-outer {
        padding-bottom: 90px;
        z-index: 2;

        @include bp(max-width, $carousel-bp) {
            padding-bottom: 84px;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    .owl-dots {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 31px;
        z-index: 3;

        .owl-dot {
            span {
                background-color: $faded-stone;
                height: 6.5px;
                width: 6.5px;
                margin: 6.5px 8.75px;
                transition: 0.08s all;
            }

            &.active span,
            &:hover span,
            span:hover {
                background-color: transparent;
                border: 1px solid $dark-sage;
                height: 10px;
                width: 10px;
                margin: 5px 7px;
            }
        }
    }
}

.owl-carousel {
    .owl-controls {
        bottom: 0;
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        margin-top: -20px;
        z-index: -1;

        .owl-prev,
        .owl-next {
            border: 1px solid $clr_input_notactive;
            border-radius: 50%;
            height: 36px;
            margin: 0;
            overflow: hidden;
            padding: 0;
            position: absolute;
            text-indent: -9999px;
            width: 36px;

            &:hover {
                border-color: $clr_brand_black;
            }
        }

        .owl-prev {
            background: $clr_white url("../images/carousel-prev.svg") -2px center no-repeat;
            left: -36px;

            &:hover {
                background: $clr_light_mid url("../images/carousel-prev.svg") -2px center no-repeat;
            }
        }

        .owl-next {
            background: $clr_white url("../images/carousel-next.svg") 0 center no-repeat;
            right: -35px;

            &:hover {
                background: $clr_light_mid url("../images/carousel-next.svg") 0 center no-repeat;
            }
        }
    }

    .owl-nav {
        margin: 0;
    }
}

.pdp-predictive-recommendations,
.pdp-recently-viewed,
.empty-cart-recently-viewed,
.no-hits-recently-viewed {
    @include bp(max-width, $carousel-bp) {
        .carousel-refresh {
            margin: 20px 0 0;
        }
    }
}
