.product-showcase {
    @extend %constrain-wrapper;
    margin-top: 36px;

    .header {
        text-align: center;
        margin: 0 auto;
        max-width: 668px;
        padding: 0 16px;

        h2 {
            margin-bottom: 12px;

            @include bp(max-width, $bp-large) {
                margin-bottom: 5px;
            }
        }

        p {
            padding: 0 5px;
        }
    }

    @include bp(min-width, $bp-large) {
        margin-top: 75px;

        .header {
            padding: 0;

            p {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .showcase {
        margin-top: 52px;

        a {
            display: block;
        }

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }

        .grid-container {
            padding-bottom: 26px;

            @include bp(max-width, $bp-medium) {
                padding-bottom: 25px;
            }
        }

        .section {
            position: relative;
            margin-bottom: 9px;

            @include bp(min-width, $bp-medium) {
                margin-bottom: 33px;
            }

            @include bp(min-width, $bp-xlarge) {
                margin-bottom: 46px;
            }

            a {
                text-decoration: none;
                position: relative;

                &::after {
                    @include position(absolute, null 0 8px 0);
                    @include linear-gradient(transparent, $brand-primary-dark);
                    content: "";
                    display: block;
                    height: 60px;
                    z-index: 1;
                    opacity: .5;

                    @include bp(min-width, $bp-large) {
                        height: 90px;
                    }

                    .IE & {
                        bottom: 7px;
                    }
                }
            }

            .content {
                position: absolute;
                bottom: 0;
                left: 0;
                color: $white;
                padding: 0 0 12px 10px;
                line-height: 17px;
                z-index: 2;

                .flag {
                    display: block;
                    margin-bottom: 3px;
                    font: normal normal 16px/16px $f-classical;
                }

                .name {
                    font: normal normal 12px/18px $f-neue-bold;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-right: 6px;
                }

                button {
                    @include plain-button-white();
                    font-size: 12px;
                    height: initial;
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin: 0 0 6px;

                    &::after {
                        margin-top: -12px;
                    }
                }

                .price {
                    letter-spacing: 0;
                    font-size: 12px;
                    font-family: $f-neue;
                }

                @include bp(min-width, $bp-xlarge) {
                    padding: 0 0 12px 10px;
                }
            }

            .zoom-expand {
                display: block;

                &::before {
                    content: $icon-zoom-expand;
                    position: absolute;
                    @extend %icon-font;
                    display: block;
                    bottom: 20px;
                    right: 11px;
                    color: $white;
                    font-size: 22px;
                    z-index: 2;
                }

                &:hover {
                    cursor: pointer;
                }

                @include bp(max-width, $bp-large) {
                    display: none;
                }
            }

            &.clear {
                clear: both;
            }
        }
    }
}

.product-collection {
    display: none;
    z-index: 10;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
        height: 96.2vh;
        background: $brand-primary-dark;

    }

    &.open {
        display: block;
        position: absolute;
        top: 2%;
        right: 1.4%;
        left: 1.5%;
        height: 96.2vh;
        max-width: 100%;
        z-index: 10;
        margin: 0 auto;
        opacity: 0;
        transition: opacity $navigation-transition-duration $navigation-transition-function;
    }

    &.show {
        opacity: 1;
    }

    .item {
        height: 96.2vh;
        width: 97.5%;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: scroll;
        background-size: cover;
        position: relative;

        .content {
            position: absolute;
            color: $white;
            top: 0;
            right: 0;
            max-width: 470px;
            width: 100%;
            background: rgba(46, 45, 42, 0.8);
            background-color: rgba(46, 45, 42, 0.8);
            @include blur-backdrop();
            text-align: center;
            padding: 35px 25px 21px;

            h2.bottom-border::after {
                border-color: $white;
            }

            p {
                font-size: 16px;
                margin: 29px 0 17px;
                line-height: 26px;
            }

            h2,
            button,
            .button {
                color: inherit;
            }

            .close {
                display: block;
                position: absolute;
                top: 0;
                right: 0;

                &::before {
                    @extend %icon-font;
                    font-size: 14px;
                    content: $icon-close-x;
                    color: $white;
                    background: $brand-primary-dark;
                    padding: 11px;
                    line-height: 36px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .flex-control-nav {
        bottom: 20px;
    }
}

.showcase {
    button {
        @include plain-button;
    }
}
.IE {
    .product-showcase .showcase .section .content button::after,
    .product-showcase .showcase .section .content button::after {
        background: url("../images/button-arrow-white.svg") no-repeat 6px 14px;
        top: 12px;
        margin-top: -11.5px;
    }
    .product-showcase .showcase .section .content {
        padding-bottom: 0;
    }
    &.edge {
        .product-showcase .showcase .section .content button::after,
        .product-showcase .showcase .section .content button::after {
            background: url("../images/button-arrow-white.svg") no-repeat 6px 14px;
            margin-top: -11.5px;
            top: 0;
            filter: none;
        }
        .product-showcase .showcase .section .content {
            padding-bottom: 12px;
        }
    }
}

