.header-hero {
    background-position: center;
    background-size: cover;
    position: relative;
    text-align: center;
    z-index: 1;

    .breadcrumb {
        z-index: 3;
    }

    &.header-hero-category-page {
        .breadcrumb {
            position: absolute;
            top: 47%;
            display: block;
            width: 100%;
            text-align: center;

            @include bp(max-width, $bp-custom-small) {
                top: 32%;
            }
        }
    }

    .header-hero-image-desktop {
        display: block;
        width: 100%;

        @include bp(max-width, $bp-small) {
            display: none;
            width: auto;
            min-width: 100%;
        }
    }

    .header-hero-image-mobile {
        display: none;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @include bp(max-width, $bp-small) {
            display: block;
            min-height: 175px;
        }
    }

    h1,
    h2 {
        display: block;
        position: absolute;
        padding: 0 15px;
        top: 60%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 3;

        @include bp(max-width, $bp-custom-small) {
            top: 44%;
        }

        a {
            text-decoration: none;
        }
    }

    .bottom-border {
        @include bottom-border($white);
    }

    .overlay {
        @include position(absolute, 0 0 0 0);
        background: $brand-primary-dark;
        content: "";
        z-index: 2;

        &.overlay-desktop {
            @include bp(max-width, $bp-small) {
                display: none;
            }
        }

        &.overlay-mobile {
            display: none;

            @include bp(max-width, $bp-small) {
                display: block;
            }
        }
    }

    @include bp(max-width, $bp-medium) {
        .bottom-border {
            padding-bottom: 17px;
        }
    }

    // pushes everything above the overlay
    > * {
        position: relative;
        z-index: 2;
    }

    &.tall {
        min-height: 500px;

        @include bp(max-width, $bp-medium) {
            min-height: 175px;
        }
    }

    &.no-image {
        h1,
        h2 {
            bottom: initial;
            position: relative;
            top: initial;
        }

        .breadcrumb {
            text-align: left;
            @include bp(max-width, $bp-large) {
                padding: 25px 15px 20px;
            }
            @include bp(max-width, 1290px) {
                padding-left: 6px;
            }
            @include bp(max-width, $bp-large) {
                margin-left: 4.98vw;
                padding-left: 0;
            }

        }

        .bottom-border::after {
            border-bottom: 1px solid $brand-primary-dark;
        }

        .overlay {
            background: none;
        }
    }

    .plp-head {
        &__header {
            text-transform: lowercase;

            &::first-line {
                text-transform: capitalize;
            }

            a {
                text-transform: capitalize;
            }
        }
    }
}
