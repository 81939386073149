#wrapper {
    &.pt_product-search-noresult,
    &.pt_error {
        #main {
            &.full-width {
                max-width: initial;
            }
        }
    }
}

.pt_product-search-noresult,
.pt_error {
    color: $black;
}

.no-hits-page-only,
.error-page-only {
    display: none;
}

.pt_product-search-noresult {
    .no-hits-page-only {
        display: inline;
    }
}

.pt_error {
    .error-page-only {
        display: inline;
    }
}

.no-hits-title {
    color: inherit;
    font: normal normal 32px/38px $f-classical-normal;
    letter-spacing: 0.01em;
    margin: 30px auto;
    padding: 0 20px;
    text-align: center;
    text-transform: initial;

    @include bp(min-width, $bp-medium) {
        font: normal normal 36px/43px $f-classical-normal;
        margin: 50px auto 40px;
    }
}

.no-hits-section-head {
    padding: 0 20px;
    text-align: center;

    &__search-term {
        font: normal normal 20px/27px $f-avenir;
        letter-spacing: 0.01em;
        margin: 0 auto 20px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 24px/33px $f-avenir;
            margin-bottom: 30px;
        }
    }

    &__description {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.01em;
        margin: 0 auto 35px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 20px/27px $f-avenir;
            margin-bottom: 64px;
        }
    }
}

.no-hits-search {
    margin: 0 auto 30px;
    max-width: 924px;
    padding: 0 32px;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 62px;
    }
}

.as-search {
    align-items: center;
    border-bottom: 1px solid $silver;
    color: $brand-v2-dark-gray;
    display: flex;
    font: normal normal 14px/19px $f-avenir;
    letter-spacing: 0.01em;
    padding-bottom: 5px;

    @include bp(min-width, $bp-medium) {
        font: normal normal 16px/22px $f-avenir;
        padding-bottom: 15px;
    }

    &::before {
        background: transparent url("../images/search.svg") center / contain no-repeat;
        content: "";
        cursor: pointer;
        height: 27px;
        margin-right: 5px;
        width: 27px;

        @include bp(min-width, $bp-medium) {
            height: 32px;
            margin-right: 20px;
            width: 32px;
        }
    }
}

.no-hits-recommended-categories {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 20px;

    &__title {
        font: normal normal 20px/27px $f-avenir;
        letter-spacing: 0.01em;
        margin: 0 auto 30px;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            font: normal normal 24px/33px $f-avenir;
            margin-bottom: 34px;
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;

        @include bp(min-width, $bp-medium) {
            margin: 0 -28px;
        }
    }
}

.no-hits-recommended-categories-tile {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 40px;
        padding: 0 28px;
        width: calc(100% / 3);
    }

    img {
        max-width: 100%;
    }

    a {
        color: inherit;
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.05em;
        text-decoration: none;

        &:focus {
            color: inherit;
        }

        + a {
            margin-top: 15px;
            text-decoration: none;

            @include bp(min-width, $bp-medium) {
                margin-top: 20px;
            }
        }
    }
}

.general-error-text {
    color: $black;
    text-align: center;
}
