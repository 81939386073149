.pt_categorylanding {
    @include bp(min-width, $bp-medium) {
        .hero-title {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 60px;
        }
    }

    .no-image .hero-title {
        padding-top: 25px;
        @include bp(min-width, $bp-medium) {
            padding-top: 32px;
        }
    }

    .primary-content {
        min-height: 0;
    }

    // Product Showcase Grid
    .product-showcase {
        margin-top: 24px;

        .showcase {
            margin-top: 14px;
        }

        @include bp(min-width, $bp-medium) {
            margin-top: 53px;

            .showcase {
                margin-top: 52px;
            }
        }

        .showcase .grid-container {
            padding-bottom: 6px;
        }

        @include bp(max-width, $bp-medium) {
            .showcase .grid-container {
                padding-bottom: 18px;
            }
        }
    }

    // Category Showcase List
    .category-showcase {
        @extend %constrain-wrapper;
        padding-top: 60px;

        @include bp(max-width, $bp-medium) {
            padding: 25px 0 0;
        }
    }

    .category-showcase-tile {
        position: relative;
        padding-bottom: 23px;
        margin-bottom: 20px;
        text-align: center;

        a {
            @include link($brand-primary-dark);
            text-decoration: none;
        }

        &::before {
            @include position(absolute, null 10px 0 10px);
            content: "";
            background-color: #ababaa;
            height: 1px;
            display: block;
            z-index: 2;
        }

        @include bp(min-width, $bp-medium) {
            padding-bottom: 29px;
            margin-bottom: 12px;

            &::before {
                @include position(absolute, null 0 8px 0);
            }
        }
    }

    .category-showcase-wrap {
        @include clearfix;
        position: relative;
    }

    .category-showcase-image {

        padding: 10px;

        img {
            width: 100%;
            max-width: 826px;
        }

        @include bp(max-width, $bp-medium) {
            padding-bottom: 5px;
        }

        @include bp(min-width, $bp-large) {
            width: calc(100% - 242px);
            max-width: 846px;
            float: left;
        }
    }

    .category-showcase-info {
        @include max-width(242px);

        @include bp(min-width, $bp-large) {
            @include transform(translateY(-50%));
            margin: -6px 0 0;
            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    .category-multi-config-options {
        margin: 0 0 24px;

        span {
            font: normal normal 10px/18px $f-neue-bold;
            background-color: $c-email-cell;
            color: $brand-primary-light;
            padding: 2px 10px 3px;
        }
    }

    .category-availability {
        margin: 0;
        padding-top: 5px;
        font: normal normal 16px/12px $f-classical;
        color: $brand-primary-dark;
    }

    .category-name {
        font: normal normal 21px/25px $f-primary;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 6px 0 20px;

        @include bp(max-width, $bp-medium) {
            margin-top: 0;
        }
    }

    .category-details {
        color: $brand-primary-dark;
        margin-bottom: 11px;

        p {
            font: normal normal 12px/18px $f-neue;
            margin: 0;
        }

        .separator {
            display: inline-block;
            margin: 0 6px;
        }
    }

    .product-swatches {
        margin-bottom: 20px;
    }

    .category-pricing {
        font: normal normal 12px/18px $f-neue;
        color: $brand-primary-dark;
    }

    .footer-container {
        @include bp(min-width, $bp-large) {
            padding-top: 59px;
        }
    }
}

.l2-category-header {
    color: $black;
    margin: 24px auto;
    padding: 0 8px;
    text-align: center;

    @include bp(min-width, $bp-medium) {
        margin: 40px auto 32px;
    }
}

.l2-category-title {
    color: inherit;
    font: normal normal 24px/28px $f-classical-normal;
    letter-spacing: 2.29px;
    margin-bottom: 8px;
    text-transform: none;

    @include bp(min-width, $bp-medium) {
        font: normal normal 36px/36px $f-classical-normal;
        letter-spacing: 1.8px;
        margin-bottom: 16px;
    }
}

.l2-category-sub-title {
    color: inherit;
    font: normal normal 12px/20px $f-avenir;
    letter-spacing: 0.67px;
    margin: 0;

    @include bp(min-width, $bp-medium) {
        font: normal normal 14px/24px $f-avenir;
        letter-spacing: normal;
    }
}

.l2-sub-category-section {
    &__wrapper {
        color: $black;

        @include bp(min-width, $bp-medium) {
            padding: 0 32px;
        }

        + .l2-sub-category-section {
            &__wrapper {
                margin-top: 24px;

                @include bp(min-width, $bp-medium) {
                    margin-top: 40px;
                }
            }
        }
    }

    &__head {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 0 8px;

        @include bp(min-width, $bp-small) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
        }
    }

    &__title {
        color: inherit;
        font: normal normal 24px/32px $f-classical-normal;
        letter-spacing: 2.29px;
        margin-bottom: 8px;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            color: inherit;
        }

        &:hover {
            text-decoration: underline;
        }

        @include bp(min-width, $bp-small) {
            margin-bottom: 0;
        }

        @include bp(min-width, $bp-medium) {
            font: normal normal 36px/52px $f-classical-normal;
            letter-spacing: 1.8px;
        }
    }

    &__items-count {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    &__count {
        border-right: 1px solid $black;
        font: normal normal 12px/18px $f-avenir;
        letter-spacing: 1.74px;
        margin-right: 16px;
        padding-right: 16px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 14px/26px $f-avenir;
            letter-spacing: 1.74px;
        }
    }

    &__shop-all {
        color: inherit;
        font: 500 normal 12px/18px $f-avenir;
        letter-spacing: normal;
        text-decoration: none;
        text-transform: uppercase;

        &:active,
        &:focus,
        &:hover {
            color: inherit;
        }

        &:hover {
            text-decoration: underline;
        }

        @include bp(min-width, $bp-medium) {
            font: 700 normal 14px/26px $f-avenir;
        }
    }

    &__body {
        position: relative;
        width: 100%;

        @include bp(min-width, $bp-medium) {
            padding: 0 28px;
        }
    }
}

.l2-sub-category-carousel {
    &__controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 107px;
        background: transparent;

        @include bp(min-width, $bp-large) {
            height: 220px;
        }

        .custom-owl-prev {
            left: 0;
            transform: translateY(-50%) rotate(135deg);
        }

        .custom-owl-next {
            right: 0;
            transform: translateY(-50%) rotate(-45deg);
        }

        .custom-owl-prev,
        .custom-owl-next {
            display: none;
            position: absolute;
            top: 50%;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            cursor: pointer;

            @include bp(min-width, $bp-medium) {
                display: block;
                width: 14px;
                height: 14px;
            }

            @include bp(min-width, $bp-large) {
                width: 22.5px;
                height: 22.5px;
            }
        }
    }

    .owl-stage {
        display: flex;
        margin-left: -30px;

        @include bp(min-width, $bp-medium) {
            margin-left: 0;
        }
    }

    .owl-item {
        display: flex;
        flex: 1 0 auto;
    }

    .owl-controls {
        display: none;
    }
}

.l2-sub-category-carousel-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__link-image,
    &__name {
        color: inherit;

        &:active,
        &:focus,
        &:hover {
            color: inherit;
        }
    }

    &__image {
        max-height: 100%;
        width: 100%;
    }

    &__link-image {
        height: 107px;
        margin-bottom: 8px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 16px;
        }

        @include bp(min-width, $bp-large) {
            height: 220px;
        }

        &:hover {
            + .l2-sub-category-carousel-item {
                &__name {
                    text-decoration: underline;
                }
            }
        }
    }

    &__name {
        font: 500 normal 8px/12px $f-avenir;
        letter-spacing: 1.5px;
        margin-bottom: 8px;
        text-decoration: none;

        @include bp(min-width, $bp-large) {
            font: 500 normal 14px/22px $f-avenir;
            letter-spacing: 2.5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
