.refinement-flyout {
    &__wrapper {
        background-color: rgba(46, 45, 42, 0.8); // IE fallback
        background: $white;
        @include blur-backdrop();
        color: $black;
        position: absolute;
        top: 0;
        height: 100vh;
        max-width: 400px;
        z-index: 99992;
        width: 100%;
        right: -400px;
        transition: all $navigation-transition-duration $navigation-transition-function;
        box-sizing: content-box;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;

        @supports (-webkit-touch-callout: none) {
            height: calc(100vh - 60px);
        }
    }

    .close {
        position: absolute;
        top: 30px;
        right: 30px;
        opacity: 1;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $black;
        }

        &::before {
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__header {
        font: normal normal 20px/28px $f-avenir;
        padding: 50px 35px 8px;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            font: normal normal 22px/30px $f-avenir;
        }
    }

    &__main-content {
        padding: 0 35px;
        overflow: auto;
    }

    &__actions {
        display: flex;
        margin-top: auto;
        padding: 30px 35px 60px;
        border-top: 1px solid $flyout-refinement-border-color;

        @include bp(min-width, $bp-large) {
            padding-bottom: 30px;
        }
    }

    &__view-results-action,
    &__clear-all {
        font-size: 14px;
        padding-left: 4px;
        padding-right: 4px;
    }

    &__view-results-action {
        flex: 60%;
    }

    &__clear-all {
        flex: 40%;
        margin-right: 12px;
    }

    .mobile-collapse-container {
        display: block;

        .collapse-elements {
            display: block;
            padding: 0;
        }
    }

    .sort-by {
        display: block;

        .radio-label {
            &.checked {
                &::after {
                    left: 5px;
                    top: 4px;
                }
            }
        }
    }

    .refinement {
        font: normal normal 14px/20px $f-avenir;
        color: inherit;
        border-top: 1px solid $flyout-refinement-border-color;
        padding: 0;

        @include bp(min-width, $bp-medium) {
            font: normal normal 16px/22px $f-avenir;
        }
    }

    .checkbox-refinements {
        margin-top: 0;
        padding: 0 0 4px;
    }

    .refinement-header {
        font: inherit;
        font-weight: 600;
        color: inherit;
        letter-spacing: initial;
        padding: 20px 15px 15px 0;
        margin: 0;

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }

        &::before {
            display: none;
        }

        &::after {
            display: block;
            width: 13.4px;
            height: 13.4px;
            border-color: $black;
        }
    }

    .refinement-link {
        font: normal normal 14px/19px $f-avenir;

        @include bp(min-width, $bp-medium) {
            font: normal normal 16px/21px $f-avenir;
        }
    }

    .refinement-color {
        .checkbox-label {
            font: inherit;
            padding-left: 40px;
            line-height: 26px;

            &::before {
                top: 0;
                left: 0;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid transparent;
                background: var(--bgColorRefinement);
            }

            &.checked {
                &::before {
                    border-color: $brand-v2-off-black;
                }

                &::after {
                    display: none;
                }
            }
        }

        .checkbox-refinements {
            flex-wrap: wrap;
            margin: 0 -12px;

            > li {
                padding: 0 12px;
                margin-bottom: 20px;
                min-width: calc(50% - 36px);
            }
        }

        .refinements-toggle {
            width: 100%;
            margin-left: 12px;
        }
    }

    .show-more-refinements {
        display: none;
    }

    .refinements-toggle {
        @include bp(max-width, $bp-medium) {
            font: normal normal 12px/17px $f-avenir;
        }
    }

    .collapse-item {
        &.refinement-color {
            .collapse-element {
                flex-direction: row;
            }
        }

        &.opened {
            .collapse-element {
                display: flex;
            }
        }
    }
}

.refinement-flyout-open {
    .refinement-flyout {
        right: 0;
    }
}
